import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';
import {BehaviorSubject} from 'rxjs';
import {delay, map, take, tap} from 'rxjs/operators';
import {Item} from './item.model';


@Injectable({
  providedIn: 'root',
})
export class ItemsService {

  public elementos = [];
  public discounts = [];
  public itemSelected = false;

  private _items= new BehaviorSubject<Item[]>([]);

  private _articulos = new BehaviorSubject<Item[]>([]);

  get items() {
    return this._items.asObservable();
  }

  get articulos() {
    return this._articulos.asObservable();
  }

  constructor(
    private loadingController: LoadingController
  ) {}

  getItem(id: number) {
    return this.items.pipe(
      take(1),
      map((items) => {
        return {...items.find((i) => i.articulo.id === id )}
      })
    )
  }

  addItem(item) {
    const newItem = item
    return this.items.pipe(
      take(1),
      delay(1500),
      tap((items) => {
        this._items.next(items.concat(newItem))
        this.loadingController.dismiss()
      })
    )
  }

  deleteItem(itemId) {
    return this.items.pipe(
      take(1),
      tap(items => {
        this._items.next(items.filter((i) => i.articulo.id !== itemId))
      })
    )
  }

  clearItems() {
    return this.items.pipe(
      take(1),
      delay(1000),
      tap(items => {
        this._items.next(items = [])
      })
    )
  }

}
