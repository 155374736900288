import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';
import {BehaviorSubject} from 'rxjs';
import {delay, take, tap} from 'rxjs/operators';
import {ApiProvider} from '../api/api';
import {InternalAnnotation} from '../internal-annotation.model';
import {Item} from '../item.model';
import {Budget} from './budget.model';
import {ItemsService} from "../items.service";

@Injectable({
  providedIn: 'root',
})
export class BudgetProvider {

  public paymentMethod: any;
  public bankAccount: any;
  public empDato: any;
  public client: any;
  public multipleTax = false;
  public budget: any;
  public newBudget = false;
  public annotations = [];
  public subtotal = 0;
  public taxBase = 0;
  public discounts = 0;
  public taxList = [];
  public tax = 0;
  public total = 0;
  public recharge = 0;
  public observations = '';
  public dataLoad = false;
  public searchText: String = '';
  public itemsSearch: Item[] = [];
  public discountGeneral: number = 0;
  public discountGeneralAfterTax: number = 0;
  public retencion: number = 0;
  public convenio: number = 0;


  constructor(
    private apiProvider: ApiProvider,
    private loadingController: LoadingController,
    private itemsService: ItemsService
  ) {
  }

  private _budgets = new BehaviorSubject<Budget[]>([]);
  private _budgetInternalAnnotations = new BehaviorSubject<InternalAnnotation[]>([]);
  chosenBudget;


  get internalAnnotations() {
    return this._budgetInternalAnnotations.asObservable();
  }

  get budgets() {
    return this._budgets.asObservable();
  }

  fetchBudgets(numPag: number, numElem: number, idCliente?: number, enVuelo?: boolean) {
    if (idCliente) {
      return this.apiProvider
        .get('presupuestos', {
          params: {
            numPag,
            numElem,
            idCliente,
            enVuelo
          },
        })
        .pipe();
    } else {
      return this.apiProvider
        .get('presupuestos', {
          params: {
            numPag,
            numElem,
          },
        });
    }
  }

  getBudget(id: number) {
    return this.apiProvider.get(`presupuestos/${id}`);
  }

  fetchBudgetAnnotations(id: number) {
    return this.apiProvider.get(`presupuestos/${id}/anotaciones`);
  }

  signBudget(id, file, fileName) {
    const formData = new FormData();
    formData.append('file', file, fileName);
    return this.apiProvider.post('presupuestos/' + id + '/firma', formData);
  }

  signBudgetSinDoc(id, clientSign) {
    return this.apiProvider.post('presupuestos/' + id + '/firmaSinDoc', clientSign);
  }

  hasDigitalSign(id) {
    return this.apiProvider.get('presupuestos/' + id + '/tieneFirmaDigital');
  }

  sendDoc(id, file, idSubtipo, fileName) {
    const formData = new FormData();
    formData.append('file', file, fileName);
    return this.apiProvider.post(
      'presupuestos/' + id + '/enviarDoc',
      formData,
      {
        params: {
          pdfEditable: true,
          envioCorreo: true,
          idSubtipo: idSubtipo,
        },
      }
    );
  }

  addBudget(
    empDato: { id: number },
    cliente: { id: number },
    observaciones: string,
    anotaciones,
    presupuestoArticulos: Item[],
  ) {
    let generatedId: string;
    // TODO metodo POST para crear un presupuesto
    // return this.apiProvider
    //   .post('presupuestos/crearPresupuesto', {
    //     ...newBudget,
    //     id: null,
    //   })
    //   .pipe(
    //   //   switchMap((resData) => {
    //   //     generatedId = resData.id;
    //   //     return this.budgets;
    //   //   }),
    //   //   take(1),
    //   //   tap((budgets) => {
    //   //     newBudget.id = generatedId;
    //   //     this._budgets.next(budgets.concat(newBudget));
    //   //   })
    //   // );
    //   tap(resData => {
    //   console.log(resData)
    // }))

    return this.budgets.pipe(
      take(1),
      delay(1500),
      tap((budgets) => {
        // this._budgets.next(budgets.concat(newBudget));
        this.loadingController.dismiss();
      })
    );
  }

  addInternalAnnotation(id: number, anotacion: string) {
    return this.apiProvider.post(`presupuestos/${id}/anotaciones`, {
      anotacion: anotacion,
    });
  }

  deleteAnnotation(budgetId: number, annotationId: number) {
    return this.apiProvider.post(
      `presupuestos/${budgetId}/anotaciones/${annotationId}`
    );
  }

  saveObservation(id: number, observacion: string) {
    return this.apiProvider.post(
      `presupuestos/${id}/observaciones?observaciones=${observacion}`
    );
  }

//  searchBudgetItem(query: string) {
//    return this.apiProvider.get(`stockWS/buscarArticulo?busqueda=${query}`)
//  }

  searchBudgetItem(query: string, idEmpDato) {
    return this.apiProvider.get(`presupuestos/elementos?busqueda=${query}&idEmpDato=${idEmpDato}`)
  }

  setChosenBudget(budget) {
    this.chosenBudget = budget
  }

  getChosenBudget() {
    if (this.chosenBudget) {
      return this.chosenBudget
    }
  }

  getItemDiscounts(idEmpDato: number, idCliente: number, idElemento: number, tipo) {
    tipo = tipo == 'ARTICULO' ? 0 : 1;
    return this.apiProvider
      .get(`presupuestos/descuentos?idEmpDato=${idEmpDato}&idCliente=${idCliente}&tipo=${tipo}&idElemento=${idElemento}`)
  }

  getDiscounts(idEmpDato: number, idCliente: number) {
    return this.apiProvider
      .get(`presupuestos/descuentosGeneral?idEmpDato=${idEmpDato}&idCliente=${idCliente}`)
  }

  createBudget(budegDTO) {
    return this.apiProvider.post('presupuestos', budegDTO);
  }

  getStates(id: number) {
    return this.apiProvider
      .get(`presupuestos/${id}/estadosHabilitados`)
  }

  changeState(id: number, idState: number) {
    return this.apiProvider
      .post(`presupuestos/${id}/estado?idNuevoEstado=${idState}`)
  }

  getPaymentMethods(idEmpDato: number, idCliente: number) {
    return this.apiProvider
      .get(`presupuestos/formasPago?idEmpDato=${idEmpDato}&idCliente=${idCliente}`)
  }

  downloadBudget(id) {
    return this.apiProvider.post('presupuestos/' + id + '/descarga', {}, {
      responseType: 'arraybuffer'
    });
  }

  editBudget(budgetId: number, budgetDTO) {
    return this.apiProvider.post(
      `presupuestos/${budgetId}`, budgetDTO);
  }

  sendMail(idPresupuesto: number, body: any) {
    return this.apiProvider
      .post(`presupuestos/${idPresupuesto}/envioCorreo`, body)
  }

  recalculate() {

    if (this.newBudget || this.dataLoad) {

      //obtener la suma de los importes de cada item
      this.taxBase = this.itemsService.elementos.reduce((accumulator, object) => {
        return accumulator + (object.desactivar ? 0 : (object.articulo.importe * object.cantidad));
      }, 0);

      //obtenermos la suma de los descuentos
      this.discounts = 0;

      // Este es el sumatorio de descuentos en un mismo producto
//    this.itemsService.elementos.forEach(x => {
//      this.sumatorioDescuentos = x.descuentos.reduce((accumulator, object) => {
//        return accumulator + object.valor;
//      }, 0);
//    });

      // Este es el sumatorio de descuentos totales de cada producto
      let descuentosTotales = this.itemsService.elementos.reduce((accumulator, object) => {
        return accumulator + (object.desactivar ? 0 : object.descuentoTotal);
      }, 0);

      // Este es el sumatorio de descuentos totales
      this.discountGeneral = this.itemsService.discounts.reduce((accumulator, object) => {
        return accumulator + object.aplicaTrasImpuestos? 0 : (object.tipo ? ((object.valor / 100) * (this.taxBase - descuentosTotales)) : object.valor);
      }, 0);

      this.discounts = descuentosTotales + this.discountGeneral;

      // Comprobamos si hay múltiples impuestos
      // En caso afirmativo, no podemos añadir descuentos generales
      this.multipleTax = false;
      let taxValue = 0;
      let rechargeValue = 0;
      this.taxList = [];
      this.recharge = 0;
      this.itemsService.elementos.forEach(x => {
        if (!x.desactivar) {

          taxValue = x.articulo.impuesto.valor;
          rechargeValue = x.articulo.impuesto.valorRecargo;

          // Agrupamos los impuestos por valor
          let taxExist = this.taxList.find(t => t.valor == x.articulo.impuesto.valor);
          if (taxExist) {
            taxExist.importe = taxExist.importe + (((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valor / 100));
            if (x.tipo != 'SERVICIO') {
              taxExist.importeRecargo = taxExist.importeRecargo + (((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valorRecargo / 100));
            }
          } else {
            if (x.tipo != 'SERVICIO') {
              this.taxList.push({
                id: x.articulo.impuesto.id,
                descripcion: x.articulo.impuesto.descripcion,
                valor: x.articulo.impuesto.valor,
                importe: ((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valor / 100),
                valorRecargo: x.articulo.impuesto.valorRecargo,
                importeRecargo: ((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valorRecargo / 100)
              });
            } else {
              this.taxList.push({
                id: x.articulo.impuesto.id,
                descripcion: x.articulo.impuesto.descripcion,
                valor: x.articulo.impuesto.valor,
                importe: ((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valor / 100),
                valorRecargo: x.articulo.impuesto.valorRecargo,
                importeRecargo: 0
              });
            }
          }

          if (x.tipo != 'SERVICIO') {
            this.recharge += (((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valorRecargo / 100));
          }
        }
      });

      // Comprobamos si hay múltiples impuestos
      this.multipleTax = this.taxList.length > 1;

      if (this.multipleTax) {
        //obtener la suma de los importes de cada item
        this.tax = this.itemsService.elementos.reduce((accumulator, object) => {
          return accumulator + (object.desactivar ? 0 : ((object.articulo.importe * object.cantidad) - object.descuentoTotal) * (object.articulo.impuesto.valor / 100));
        }, 0);
    /*    this.recharge = this.itemsService.elementos.reduce((accumulator, object) => {
          return accumulator + object.tipo != 'SERVICIO'? (((object.articulo.importe * object.cantidad) - object.descuentoTotal) * (object.articulo.impuesto.valorRecargo / 100)) : 0;
        }, 0);  */
      } else {
        this.tax = (this.taxBase - this.discounts) * (taxValue / 100);
 //       this.recharge = (this.taxBase - this.discounts) * (rechargeValue / 100);
      }

      // Controlar solo servicio si no descuento general
  /*   this.recharge = this.itemsService.elementos.reduce((accumulator, object) => {
        return accumulator + object.tipo != 'SERVICIO'? (((object.articulo.importe * object.cantidad) - object.descuentoTotal) * (object.articulo.impuesto.valorRecargo / 100)) : 0;
      }, 0);   */

      //    this.budgetProvider.taxBase = this.totalPresupuesto;
      this.subtotal = this.taxBase - this.discounts;

      // Este es el sumatorio de descuentos totales D.D.I.
      this.discountGeneralAfterTax = this.itemsService.discounts.reduce((accumulator, object) => {
        return accumulator + !object.aplicaTrasImpuestos? 0 : ((object.tipo ? ((object.valor / 100) * (this.subtotal + this.tax)) : object.valor));
      }, 0);

      this.retencion = !this.budget? 0 : !this.budget.valorRetencion? 0 : this.subtotal * (this.budget.valorRetencion/100);
      this.convenio = !this.budget? 0 : !this.budget.valorConvenio? 0 : this.subtotal * (this.budget.valorConvenio/100);

      // Si solo tenemos un impuesto, lo calculamos sobre la base imponible total ya que puede haber descuentos A.I.
      if (this.taxList.length == 1) {
        this.taxList[0].importe = this.tax;
        if (this.discountGeneral > 0) {
          this.taxList[0].importeRecargo = (this.subtotal) * (rechargeValue / 100);
          this.recharge = (this.subtotal) * (rechargeValue / 100);
        }
      }

      if (!this.aplicaRecargo()) {
        this.total = this.subtotal + this.tax - this.discountGeneralAfterTax - this.retencion - this.convenio;
      } else {
        this.total = this.subtotal + this.tax + this.recharge - this.discountGeneralAfterTax - this.retencion - this.convenio;
      }

    } else {
      this.observations = this.budget.observaciones ? this.budget.observaciones : '';
      this.annotations = this.budget.anotaciones ? this.budget.anotaciones : [];
      this.paymentMethod = this.budget.formaPago ? this.budget.formaPago : null;
      this.bankAccount = this.budget.informacionBancaria ? this.budget.informacionBancaria : null;
  //    this.taxList = this.budget.impuestoDTOS;
      //   this.itemsService.elementos = this.budgetProvider.budget.presupuestoArticulos;
      this.budget.presupuestoArticulos.forEach(x => {

        this.itemsService.elementos.push({
          id: x.id,
          cantidad: x.cantidad,
          descuentos: x.descuentos,
          descuentoTotal: 0,
          tipo: x.tipo,
          elementos: [],
          articulo: {
            id: x.articulo.id,
            importe: x.articulo.importe,
            impuesto: x.articulo.impuesto,
            valor: x.articulo.valor,
            referencia: x.articulo.referencia,
            descripcion: x.articulo.descripcion,
            desactivar: false
          }
        })
      });

      this.budget.presupuestoDescuentos.forEach(x => {

          this.itemsService.discounts.push({
            id: x.id,
            descripcion: x.descripcion,
            valor: x.valor,
            tipo: x.tipo,
            editable: x.editable,
            aplicaTrasImpuestos: x.aplicaTrasImpuestos
          });

      });

      this.dataLoad = true;

      //obtener la suma de los importes de cada item
      this.taxBase = this.itemsService.elementos.reduce((accumulator, object) => {
        return accumulator + (object.articulo.importe * object.cantidad);
      }, 0);

      //obtenermos la suma de los descuentos
      this.discounts = 0;

      // Este es el sumatorio de descuentos en un mismo producto
      this.itemsService.elementos.forEach(x => {
        x.descuentoTotal = x.descuentos.reduce((accumulator, object) => {
          return accumulator + (object.tipo ? ((object.valor / 100) * (x.articulo.importe * x.cantidad)) : object.valor)
        }, 0);
      });

      // Este es el sumatorio de descuentos totales de cada producto
      let descuentosTotales = this.itemsService.elementos.reduce((accumulator, object) => {
        return accumulator + object.descuentoTotal;
      }, 0);

      // Este es el sumatorio de descuentos totales
      this.discountGeneral = this.itemsService.discounts.reduce((accumulator, object) => {
        return accumulator + object.aplicaTrasImpuestos? 0 : ((object.tipo ? ((object.valor / 100) * (this.taxBase - descuentosTotales)) : object.valor));
      }, 0);

      this.discounts = descuentosTotales + this.discountGeneral;

      // Comprobamos si hay múltiples impuestos
      // En caso afirmativo, no podemos añadir descuentos generales
      this.multipleTax = false;
      let taxValue = 0;
      let rechargeValue = 0;
      this.taxList = [];
      this.recharge = 0;
      this.itemsService.elementos.forEach(x => {

        taxValue = x.articulo.impuesto.valor;
        rechargeValue = x.articulo.impuesto.valorRecargo;

        // Agrupamos los impuestos por valor
        let taxExist = this.taxList.find(t => t.valor == x.articulo.impuesto.valor);
        if (taxExist) {
          taxExist.importe = taxExist.importe + (((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valor / 100));
          if (x.tipo != 'SERVICIO') {
            taxExist.importeRecargo = taxExist.importeRecargo + (((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valorRecargo / 100));
          }
        } else {
          if (x.tipo != 'SERVICIO') {
            this.taxList.push({
              id: x.articulo.impuesto.id,
              descripcion: x.articulo.impuesto.descripcion,
              valor: x.articulo.impuesto.valor,
              importe: ((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valor / 100),
              valorRecargo: x.articulo.impuesto.valorRecargo,
              importeRecargo: ((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valorRecargo / 100)
            });
          } else {
            this.taxList.push({
              id: x.articulo.impuesto.id,
              descripcion: x.articulo.impuesto.descripcion,
              valor: x.articulo.impuesto.valor,
              importe: ((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valor / 100),
              valorRecargo: x.articulo.impuesto.valorRecargo,
              importeRecargo: 0
            });
          }
        }

        if (x.tipo != 'SERVICIO') {
          this.recharge += (((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valorRecargo / 100));
        }
      });

      this.multipleTax = this.taxList.length > 1;

      this.tax = 0;
  //    this.recharge = 0;

      if (this.multipleTax) {
        //obtener la suma de los importes de cada item
        this.tax = this.itemsService.elementos.reduce((accumulator, object) => {
          return accumulator + ((object.articulo.importe * object.cantidad) - object.descuentoTotal) * (object.articulo.impuesto.valor / 100);
        }, 0);

  /*      this.recharge = this.itemsService.elementos.reduce((accumulator, object) => {
          return accumulator + object.tipo != 'SERVICIO'? (((object.articulo.importe * object.cantidad) - object.descuentoTotal) * (object.articulo.impuesto.valorRecargo / 100)) : 0;
        }, 0);  */
      } else {
        this.tax = (this.taxBase - this.discounts) * (taxValue / 100);
 //       this.recharge = (this.taxBase - this.discounts) * (rechargeValue / 100);
      }

      // Controlar solo servicio si no descuento general
/*      this.recharge = this.itemsService.elementos.reduce((accumulator, object) => {
        console.log(object.tipo);
        if (object.tipo != 'SERVICIO') {
          return accumulator + (((object.articulo.importe * object.cantidad) - object.descuentoTotal) * (object.articulo.impuesto.valorRecargo / 100));
        } else {
          return accumulator + 0;
        }
      }, 0);  */

      //    this.taxBase = this.totalPresupuesto;
      this.subtotal = this.taxBase - this.discounts;

      // Este es el sumatorio de descuentos totales D.D.I.
      this.discountGeneralAfterTax = this.itemsService.discounts.reduce((accumulator, object) => {
        return accumulator + !object.aplicaTrasImpuestos? 0 : ((object.tipo ? ((object.valor / 100) * (this.subtotal + this.tax)) : object.valor));
      }, 0);

      this.retencion = !this.budget? 0 : !this.budget.valorRetencion? 0 : this.subtotal * (this.budget.valorRetencion/100);
      this.convenio = !this.budget? 0 : !this.budget.valorConvenio? 0 : this.subtotal * (this.budget.valorConvenio/100);

      // Si solo tenemos un impuesto, lo calculamos sobre la base imponible total ya que puede haber descuentos A.I.
      if (this.taxList.length == 1) {
        this.taxList[0].importe = this.tax;
        if (this.discountGeneral > 0) {
          this.taxList[0].importeRecargo = (this.subtotal) * (rechargeValue / 100);
          this.recharge = (this.subtotal) * (rechargeValue / 100);
        }
      }

      if (!this.aplicaRecargo()) {
        this.total = this.subtotal + this.tax - this.discountGeneralAfterTax - this.retencion - this.convenio;
      } else {
        this.total = this.subtotal + this.tax + this.recharge - this.discountGeneralAfterTax - this.retencion - this.convenio;
      }
    }
  }

  aplicaRecargo() {
    if (!this.budget) {
      return false;
    }
    return this.budget.recargo.split('€')[0].replace(',', '.') > 0;
  }

}
