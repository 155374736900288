/* eslint-disable @typescript-eslint/ban-types */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Platform} from '@ionic/angular';
import * as Constants from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiProvider {
  // url = 'https://login.beyondup.cl/crm_erp/api-rest/';
  // url = 'https://login.beyondup.es/crm_erp/api-rest/';
  // url = 'https://corporatepre.beyondup.es/crm_erp/api-rest/';
  url = 'https://corporatepre.beyondup.es/crm_erp/api-rest/';
  //url = 'http://13.37.57.57:8080/crm_erp/api-rest/';
  //url = 'http://localhost:8080/erpmodularizado/api-rest/';

  // Para cambiar la API_KEY ir a src/environments/environments.ts

  headers: {};
  items: any;
  connection: any;

  constructor(public http: HttpClient, public platform: Platform) {
  }

  post(module: string, body?: any, params?: {}) {
    return this.http.post(this.url + module, body, params);
  }

  get(module: string, options?: {}) {
    return this.http.get(this.url + module, options);
  }

  delete(module: string, options?: {}) {
    return this.http.delete(this.url + module, options);
  }

  public getApiKey(): string {
    return Constants.environment.beyondUpAPIKey;
  }
}
