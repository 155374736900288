import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {InterceptorModule} from './modules/interceptor.module';
import {SignModalComponent} from './modals/sign-modal/sign-modal.component';
import {SignatureProvider} from './providers/signature/signature';
import {SignaturePadModule} from 'angular2-signaturepad';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {BudgetInfoModalComponent} from './modals/budget-info-modal/budget-info-modal.component';
import {ClientTaxDataModalComponent} from './modals/client-tax-data-modal/client-tax-data-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClientNewBudgetItemModalComponent} from './modals/client-new-budget-item-modal/client-new-budget-item-modal.component';
import {PopoverComponent} from './components/popover/popover.component';
import {ClientNewBudgetSummaryModalComponent} from './modals/client-new-budget-summary-modal/client-new-budget-summary-modal.component';
import {ClientNewBudgetDiscountsModalComponent} from './modals/client-new-budget-discounts-modal/client-new-budget-discounts-modal.component';
import {ClientNewBudgetMethodPaymentModalComponent} from './modals/client-new-budget-method-payment-modal/client-new-budget-method-payment-modal.component';
import {ClientNewBudgetAnnotationsModalComponent} from './modals/client-new-budget-annotations-modal/client-new-budget-annotations-modal.component';
import {ClientNewBudgetPointsModalComponent} from './modals/client-new-budget-points-modal/client-new-budget-points-modal.component';
import {ClientNewBudgetDocumentsModalComponent} from './modals/client-new-budget-documents-modal/client-new-budget-documents-modal.component';
import {ClientNewBudgetSuccessfullySavedBudgetModalComponent} from './modals/client-new-budget-successfully-saved-budget-modal/client-new-budget-successfully-saved-budget-modal.component';
import {ClientContactsPageModule} from './pages/client/client-contacts/client-contacts.module';
import {ClientDelegationsPageModule} from './pages/client/client-delegations/client-delegations.module';
import {EmailComposer} from "@awesome-cordova-plugins/email-composer/ngx";
import {IonicSelectableModule} from "ionic-selectable";
import {SendBudgetEmailModalComponent} from "./modals/send-budget-email-modal/send-budget-email-modal.component";
import {CallNumber} from "@awesome-cordova-plugins/call-number/ngx";
import {SocialSharing} from "@awesome-cordova-plugins/social-sharing/ngx";
import {File} from "@awesome-cordova-plugins/file/ngx";
import {FileTransfer} from "@awesome-cordova-plugins/file-transfer/ngx";

@NgModule({
  declarations: [
    AppComponent,
    SignModalComponent,
    BudgetInfoModalComponent,
    ClientTaxDataModalComponent,
    ClientNewBudgetItemModalComponent,
    PopoverComponent,
    ClientNewBudgetSummaryModalComponent,
    ClientNewBudgetDiscountsModalComponent,
    ClientNewBudgetMethodPaymentModalComponent,
    ClientNewBudgetAnnotationsModalComponent,
    ClientNewBudgetPointsModalComponent,
    ClientNewBudgetDocumentsModalComponent,
    ClientNewBudgetSuccessfullySavedBudgetModalComponent,
    SendBudgetEmailModalComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
    }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    IonicSelectableModule,
    ReactiveFormsModule,
    InterceptorModule,
    SignaturePadModule,
    BrowserAnimationsModule,
    ClientContactsPageModule,
    ClientDelegationsPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SignatureProvider,
    EmailComposer,
    CallNumber,
    SocialSharing,
    File,
    FileTransfer
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
