import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { BankAccount } from './bank-account.model';
import { PaymentMethod } from './utils/payment-method.model';


@Injectable({
  providedIn: 'root',
})
export class BankAccountsService {
  private _bankAccounts = new BehaviorSubject<BankAccount[]>([
    new BankAccount(
      'b1',
      'ES7100811182879643566762',
      'PPPV001',
    ),
    new BankAccount(
      'b2',
      'ES3800118822479575670635',
      'PPPV001',
      ),
      new BankAccount(
      'b3',
      'ES6300814419522324574508',
      'PPPV001',
      ),
    ])

    private _paymentMethod = new BehaviorSubject<PaymentMethod[]>([])

    get paymentMethod() {
      return this._paymentMethod.asObservable()
    }
  constructor(
    private loadingController: LoadingController
  ) {}

  getPaymentMethod(id: string) {
    return this.bankAccounts.pipe(
      take(1),
      map(bankAccounts => {
        return {...bankAccounts.find(b => b.id === id)}
      })
    )
  }

  addPaymentMethod(formaPago: string, cuenta: string) {
    const newPaymentMethod = new PaymentMethod(
      Math.random().toString(),
      formaPago,
      cuenta,
    )
    return this.paymentMethod.pipe(
      take(1),
      tap(paymentMethod => {
        this._paymentMethod.next(paymentMethod.concat(newPaymentMethod))
        this.loadingController.dismiss()
      })
    )
  }

  get bankAccounts() {
    return this._bankAccounts.asObservable();
  }

}
