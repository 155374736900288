import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  LoadingController,
  ModalController,
  NavController,
  ToastController,
} from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ClientDelegationDataModalComponent } from 'src/app/modals/client-delegation-data-modal/client-delegation-data-modal.component';
import { ClientProvider } from 'src/app/providers/client/client';
import { Client } from '../client.model';

@Component({
  selector: 'app-client-delegations',
  templateUrl: './client-delegations.page.html',
  styleUrls: ['./client-delegations.page.scss'],
})
export class ClientDelegationsPage implements OnInit {
  client: Client;
  clientSub: Subscription;
  localidades: string[];
  provincias: string[];
  pais;
  localizacion;
  loadedCountries = [];
  loadedRoadTypes = [];
  loadedDelegations = [];

  constructor(
    private clientProvider: ClientProvider,
    private loadingController: LoadingController,
    private navCtrl: NavController,
    private modalController: ModalController,
    private route: ActivatedRoute,
    private toastController: ToastController
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap) => {
      if (!paramMap.has('clienteId')) {
        this.navCtrl.navigateBack('/search');
        this.loadingController.dismiss();
        return;
      } else {
        this.clientSub = this.clientProvider
          .getClient(+paramMap.get('clienteId'))
          .subscribe((client) => {
            this.client = client;
          });
      }
    });
    this.getDelegations();

    //Inicializo los arrays de los options del los selects del modal aqui
    // if(this.client.delegacionPrincipal) {
    // this.pais = this.client.delegacionPrincipal?.codigoPostal?.paisDTO.id
    // this.getLocalizacion(this.client.delegacionPrincipal.codigoPostal.codigoPostal, this.pais)
    // this.getCountries()
    // this.getRoadtipes()
    // }
  }

  onClickBack() {
    this.navCtrl.navigateBack(`client/${this.client.id}/client-detail`);
  }

  onOpenClientDelegationFormDataModal(client, isNewDelegation) {
    this.modalController
      .create({
        component: ClientDelegationDataModalComponent,
        cssClass: 'client-delegation-data-modal',
        componentProps: {
          client,
          isNewDelegation,
          localidades: this.localidades,
          provincias: this.provincias,
          pais: this.pais,
          loadedCountries: this.loadedCountries,
          loadedRoadTypes: this.loadedRoadTypes,
        },
      })
      .then((modalEl) => {
        modalEl.present();
      });
  }

  getLocalizacion(cp, idPais) {
    this.clientProvider.getLocalizacion(cp, idPais).subscribe(
      (data) => {
        // this.cp = data['object'];
        this.localizacion = data['object'];
        if (data['codigo'] == '200') {
          this.localidades = this.localizacion.listLocalizacion1;
          this.provincias = this.localizacion.listLocalizacion2;
        } else {
          this.toastError(data['mensaje']);
        }
      },
      (err) => {
        this.toastError(err.message);
      }
    );
  }

  getCountries() {
    this.clientProvider.getCountries().subscribe((data) => {
      if (data['codigo'] != 200) {
        this.loadingController
          .dismiss()
          .then(() => this.toastError(data['mensaje']));
      } else {
        this.loadedCountries = data['object'];
      }
    });
  }

  getRoadtipes() {
    this.clientProvider.getRoadTypes().subscribe((data) => {
      if (data['codigo'] != 200) {
        this.loadingController
          .dismiss()
          .then(() => this.toastError(data['mensaje']));
      } else {
        Object.keys(data['object']).forEach((key) => {
          this.loadedRoadTypes.push({
            id: key,
            descripcion: data['object'][key],
          });
        });
      }
    });
  }

  getDelegations() {
    this.loadingController
      .create({
        message: '',
        keyboardClose: true,
      })
      .then((loadingEl) => {
        loadingEl.present();
        this.clientProvider
          .getDelegations(this.client.id)
          .subscribe((data) => {
            if (data['codigo'] != 200) {
              this.loadingController
                .dismiss()
                .then(() => this.toastError(data['mensaje']));
            } else {
              this.loadedDelegations = data['object'];
            }
            this.loadingController.dismiss();
          });
      });
  }

  onAddNewDelegation() {
    this.onOpenClientDelegationFormDataModal(this.client, true);
  }

  toastError(msg) {
    this.toastController
      .create({
        message: msg,
        duration: 1500,
        position: 'bottom',
      })
      .then((toastEl) => {
        toastEl.present();
      });
  }
}
