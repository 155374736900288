export class Discount {
  constructor(
    public id: number,
    public descripcion: string,
    public valor: number,
    public tipo: boolean,
    public editable: boolean,
    public valorMaximo: boolean,
    public desactivar: boolean = false,
    public aplicaTrasImpuestos = false
  ) {  }
}

