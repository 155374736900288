import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ClientDelegationsPageRoutingModule } from './client-delegations-routing.module';

import { ClientDelegationsPage } from './client-delegations.page';
import { ClientDelegationDataModalComponent } from 'src/app/modals/client-delegation-data-modal/client-delegation-data-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ClientDelegationsPageRoutingModule
  ],
  declarations: [ClientDelegationsPage, ClientDelegationDataModalComponent]
})
export class ClientDelegationsPageModule {}
