import {AlertController, ToastController} from '@ionic/angular';

export async function errorToastMessage(
  str: string,
  toastController?: ToastController
) {
  if (!toastController) return;
  (
    await toastController.create({
      message: str != '' ? str : 'No dispone de acceso a la herramienta',
      duration: 1500,
      position: 'bottom',
    })
  ).present();
}

export function checkStorageLimit(data, alertCtrl: AlertController) {
  let msg = '';
  if (data['object']) {
    msg = 'Se ha superado el límite de almacenamiento';
  } else {
    msg = 'Se ha producido un error en el servidor';
  }
  alertCtrl.create({
    header: 'Atención',
    subHeader: 'No se ha podido generar el documento con la firma',
    message: msg,
    buttons: [
      {
        text: 'Aceptar',
      },
    ],
  });
}

export function bytesToSize(bytes, decimals = 0) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}

export const round = (n: number, precision = 2): number =>
  Math.round(n * Math.pow(10, precision)) / Math.pow(10, precision);

export const thousandSeparate = (n: number, symbol = '.'): string =>
  n.toLocaleString('es-ES', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const IBANSeparateCharacters = (str: string): string =>
  str
    .replace(/[^\dA-Z]/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim();

export async function toastMessage(msg = 'No hay conexión con el servidor', toastController?: ToastController) {
  if (!toastController) return;
  (
    await toastController
      .create({
        message: msg,
        duration: 1500,
        position: 'bottom',
      })
    ).present()
}


export function checkResponseCode(code) {
  return code == 200;
}
