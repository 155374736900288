import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IonItemSliding, LoadingController, ModalController, NavController, PopoverController,} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {AuthService} from 'src/app/auth/auth.service';
import {PopoverComponent} from 'src/app/components/popover/popover.component';
import {Client} from 'src/app/pages/client/client.model';
import {BankAccountsService} from 'src/app/providers/bank-account.service';
import {BudgetProvider} from 'src/app/providers/budget/budget';
import {Budget} from 'src/app/providers/budget/budget.model';
import {Documento} from 'src/app/providers/document.model';
import {DocumentsService} from 'src/app/providers/documents.service';
import {InternalAnnotation} from 'src/app/providers/internal-annotation.model';
import {InternalAnnotationsService} from 'src/app/providers/internal-annotations.service';
import {Item} from 'src/app/providers/item.model';
import {ItemsService} from 'src/app/providers/items.service';
import {PaymentMethod} from 'src/app/providers/utils/payment-method.model';
import {round, thousandSeparate} from 'src/app/providers/utils/utils';
import {BudgetInfoModalComponent} from '../budget-info-modal/budget-info-modal.component';
import {ClientNewBudgetSuccessfullySavedBudgetModalComponent} from '../client-new-budget-successfully-saved-budget-modal/client-new-budget-successfully-saved-budget-modal.component';

// Factory function
const getEmptyBudget = (): Budget => {
  return {
    id: null,
    cliente: null,
    baseImponible: '',
    convenio: '',
    descuentoTrasImpuesto: '',
    descuentos: '',
    empDato: '',
    estado: '',
    fecha: '',
    fechaFirma: '',
    gastosImputable: '',
    impuestoDTOS: null,
    numPresupuesto: '',
    observaciones: '',
    otros: '',
    recargo: '',
    retencion: '',
    subtotal: '',
    presupuestoArticulos: null,
    presupuestoDescuentos: null,
    presupuestoOtros: null,
    tipoServicioPresupuestoDTOS: null,
    documentos: null,
    puntos: null,
    anotaciones: null,
    paymentMethod: null,
    totalPresupuesto: '',
    valorImpuesto: '',
    valorRetencion: 0,
    valorConvenio: 0
  };
};

@Component({
  selector: 'app-client-new-budget-summary-modal',
  templateUrl: './client-new-budget-summary-modal.component.html',
  styleUrls: ['./client-new-budget-summary-modal.component.scss'],
})
export class ClientNewBudgetSummaryModalComponent implements OnInit, OnDestroy {
  @Input() budget: Budget = getEmptyBudget()
  @Input() item;
  @Input() itemCantidad;

  @Input() client: Client;
  @Input() documentos: Documento[];
  @Input() puntos: number = 4;
  @Input() internalAnnotations: InternalAnnotation[];
  @Input() observaciones: string;

  totalPresupuesto;
  ivaTotal;
  sumatorioDescuentos: number;
  isTrashcanCliked: boolean = false;
  itemId: string;

  paymentMethod: PaymentMethod[];

  addedItems: Item[];
  loadedItems: Item[];
  budgetItemsList = []

  clientSub: Subscription;
  itemsSub: Subscription;
  documentsSub: Subscription;
  discountsSub: Subscription;
  annotationSub: Subscription;
  observacionesSub: Subscription;
  paymentSub: Subscription;

  budgetDate: string;
  todayDate = new Date();
  todayDateFormated = this.formatDate(this.todayDate);
  empDato

  public round = round;
  public thousandSeparate = thousandSeparate;

  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController,
    private navController: NavController,
    private loadingController: LoadingController,
    private itemsService: ItemsService,
    private budgetProvider: BudgetProvider,
    private documentsService: DocumentsService,
    private internalAnnotationsService: InternalAnnotationsService,
    private bankAccountsService: BankAccountsService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.authService.empDato.subscribe(empDato => {
      this.empDato = empDato
     })

    this.documentsSub = this.documentsService.documents.subscribe(
      (documentos) => {
        this.documentos = documentos;
      }
    );

      this.annotationSub = this.internalAnnotationsService.internalAnnotations.subscribe(
        (annotations) => {
          this.internalAnnotations = annotations;
        }
      );

      this.observacionesSub = this.internalAnnotationsService.observaciones.subscribe(
        (observaciones) => {
          this.observaciones = observaciones;
        }
      );

        this.itemsSub = this.itemsService.items.subscribe((items) => {
          this.loadedItems = items;
    });

    this.paymentSub = this.bankAccountsService.paymentMethod.subscribe(
      (paymentMethod) => {
        this.paymentMethod = paymentMethod;
      }
    );

    //obtener la suma de los importes de cada item
    if(this.budget) {
      this.budgetDate = `${this.budget.fecha.dayOfMonth}/${this.budget.fecha.monthValue}/${this.budget.fecha.year}`;
      this.totalPresupuesto = this.budget.totalPresupuesto
      this.ivaTotal = this.budget.subtotal * 0.21
      this.sumatorioDescuentos = this.budget.descuentos +
      this.createBudgetItemsList()
    } else {
      this.totalPresupuesto = this.loadedItems.reduce((accumulator, object) => {
        return accumulator + (object.articulo.importe * object.cantidad);
      }, 0);
      //obtenemos total de iva
      this.ivaTotal = this.totalPresupuesto * 0.21;
      //obtenermos la suma de los descuentos
      this.sumatorioDescuentos = 0

      // this.loadedItems.reduce(
      //   (accumulator, object) => {
      //     return accumulator + object.descuentos.valor;
      //   },
      //   0
      // );
    }
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date) {
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }

  createBudgetItemsList() {
    if(this.budget.presupuestoArticulos) {
      this.budget.presupuestoArticulos.forEach(element => {
        this.budgetItemsList.push(element)
      });
    }
    if(this.budget.presupuestoOtros) {
      this.budget.presupuestoOtros.forEach(element => {
        this.budgetItemsList.push(element)
      });
    }
    if(this.budget.presupuestoServicios) {
      this.budget.presupuestoServicios.forEach(element => {
        this.budgetItemsList.push(element)
      });
    }
  }

  openBudgetInfoModal() {
    this.modalController
      .create({
        component: BudgetInfoModalComponent,
        cssClass: 'budget-info-modal',
        componentProps: {
          budget: this.budget,
        },
      })
      .then((modalEl) => {
        modalEl.present();
      });
  }

  onOpenSuccessfullySavedBudgetModal(newBudget) {
    this.modalController
      .create({
        component: ClientNewBudgetSuccessfullySavedBudgetModalComponent,
        componentProps: {
          newBudget
        },
      })
      .then((modalEl) => {
        modalEl.present();
      });
  }

  async createPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'popover',
      event: ev,
      translucent: true,
      componentProps: {
        isSummary: true,
        item: this.item,
        itemCantidad: this.itemCantidad,
        budget: this.budget,
        client: this.client
      },
    });
    return await popover.present();
  }

  onClickDeleteItem(itemId: string, slidingEl: IonItemSliding) {
    slidingEl.close();
    this.isTrashcanCliked = true;
    this.itemId = itemId;
  }

  clickedButton(str: string) {
    if (str == 'no') {
      this.isTrashcanCliked = false;
    } else {
      //borrar el item
      this.itemsService.deleteItem(this.itemId).subscribe();
      //cambiamos la ventana de pregunta eliminar descuento
      this.isTrashcanCliked = false;

      //recalculamos todos los totales
      this.totalPresupuesto = this.loadedItems.reduce((accumulator, object) => {
          return accumulator + object.articulo.importe;
        }, 0);

      this.ivaTotal = this.totalPresupuesto * 0.21

      this.sumatorioDescuentos = this.loadedItems.reduce((accumulator, object) => {
          return accumulator + object.descuentoTotal;
        }, 0);

      this.itemsService.items.subscribe((items) => {
        this.loadedItems = items;
        if (this.loadedItems.length === 0 && this.client) {
          this.navController.navigateForward(
            `client/${this.client.id}/new-budget`
          );
          this.onDismiss();
        } else {
          return
        }
      });
    }
  }

  onAddNewItemBudget() {
    this.onDismiss();
    this.navController.navigateForward(`client/${this.client.id}/new-budget`);
    this.budgetProvider.setChosenBudget(this.budget)
  }


  onCreateNewBudget() {

    // this.annotationSub = this.internalAnnotationsService.internalAnnotations.subscribe(
    //   (annotations) => {
    //     this.internalAnnotations = annotations;
    //   }
    // );

    // this.observacionesSub = this.internalAnnotationsService.observaciones.subscribe(
    //   (observaciones) => {
    //     this.observaciones = observaciones;
    //   }
    // );
    // this.loadingController
    //   .create({
    //     message: 'Guardando presupuesto...',
    //     spinner: 'crescent',
    //   })
    //   .then((loadingEl) => {
    //     loadingEl.present();
    //     // Simulo crear el presupuesto en la variable newBudget ***
    //     // TODO crear el presupuesto y obtenerlo por el Id que me devuelve el WS si la peticion es correcta
    //     // const newBudget = new NewBudget(
    //     //   {id: this.client.id},
    //     //   this.empDato,
    //     //   this.loadedItems,
    //     //   this.todayDateFormated,
    //     //   this.totalPresupuesto,

    //     // );


    //     this.budgetProvider
    //       .addBudget(
    //         {id: this.empDato},
    //         {id: this.client.id},
    //         this.observaciones,
    //         this.annotationSub,
    //         this.loadedItems,
    //       )
    //       .subscribe((data) => {
    //         if(data['codigo']  == '200') {
    //           console.log(data)
    //           // this.onOpenSuccessfullySavedBudgetModal(newBudget);

    //         }
    //         this.itemsSub = this.itemsService
    //         .clearItems()
    //         .subscribe((items) => {
    //           this.loadedItems = items;
    //           console.log('loadeditems', this.loadedItems);
    //         });
    //         this.modalController.dismiss();

    //       });
    //   });
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    if (this.clientSub) {
      this.clientSub.unsubscribe();
    }
    if (this.itemsSub) {
      this.itemsSub.unsubscribe();
    }
    if (this.documentsSub) {
      this.documentsSub.unsubscribe();
    }
    if (this.discountsSub) {
      this.discountsSub.unsubscribe();
    }
    if (this.annotationSub) {
      this.annotationSub.unsubscribe();
    }
    if (this.paymentSub) {
      this.paymentSub.unsubscribe();
    }
    if (this.observacionesSub) {
      this.observacionesSub.unsubscribe();
    }
  }
}
