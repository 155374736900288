import {Injectable} from '@angular/core';
import {AlertController} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class UtilProvider {

  constructor(private alertCtrl: AlertController) {
  }

  async alert(header, subHeader, message?) {
    let alert = await this.alertCtrl.create({
      header: header,
      subHeader: subHeader,
      message: message ? message : '',
      buttons: [
        {
          text: 'Aceptar',
        },
      ],
    });

    await alert.present();
  }

}
