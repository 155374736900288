import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LoadingController, ModalController, Platform} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {Documento} from 'src/app/providers/document.model';
import {DocumentsService} from 'src/app/providers/documents.service';
import {bytesToSize, checkResponseCode, toastMessage} from 'src/app/providers/utils/utils'
import {GeneralProvider} from "../../providers/general/general";
import {BudgetProvider} from "../../providers/budget/budget";
import {DomSanitizer} from "@angular/platform-browser";
import {FileTransfer, FileTransferObject} from "@awesome-cordova-plugins/file-transfer/ngx";
import {File as FilePlugin} from "@awesome-cordova-plugins/file/ngx";

@Component({
  selector: 'app-client-new-budget-documents-modal',
  templateUrl: './client-new-budget-documents-modal.component.html',
  styleUrls: ['./client-new-budget-documents-modal.component.scss'],
})
export class ClientNewBudgetDocumentsModalComponent implements OnInit {
  isNewDocument: boolean = false;
  formDocument: FormGroup;
  documents: Documento[];
  documentsSub: Subscription;
  newDocumento: File;
  today = new Date();
  date = this.today.getDate() + '/' + (this.today.getMonth() + 1) + '/' + this.today.getFullYear();
  documentsBudget = [];
  blob: Blob;
  fileName = '';
  tipo: any;
  subtipo: any;
  documentacionGeneral = [];
  subtipos = [];
  pdfUrl: any;
  isTrashcanCliked: boolean = false;

  private byteTosize = bytesToSize;
  indexDoc = 0;
  docSelected: any;

  constructor(
    private modalController: ModalController,
    private loadingController: LoadingController,
    private documentsService: DocumentsService,
    private generalProvider: GeneralProvider,
    private budgetProvider: BudgetProvider,
    private sanitizer: DomSanitizer,
    private platform: Platform,
    private file: FilePlugin,
    private transfer: FileTransfer
  ) {
  }

  ngOnInit() {
    //  this.documentsSub =
    //    this.documentsService.documents.subscribe(
    //      (documents) => {
    //        this.documents = documents;
    //      }
    //    );

    this.loadDocs();

    this.formDocument = new FormGroup({
      tipoDocumento: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      subTipoDocumento: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required]
      })
    });
  }

  onAddNewDocument(event) {
    this.loadFileFromDevice(event);

    this.loadingController
      .create({
        message: '',
        keyboardClose: true,
      }).then((loadingEl) => {
      loadingEl.present();
      this.generalProvider.obtenerTipoDocumentacion().subscribe(
        (data) => {
          if (data["codigo"] != 200) {
            loadingEl.dismiss().then(() => toastMessage(data["mensaje"]));
          } else {
            this.documentacionGeneral = data['object'];
            loadingEl.dismiss().then(() => this.isNewDocument = true);
          }
        },
        (err) => {
          loadingEl.dismiss().then(() => toastMessage());
        }
      )
    });

  }

  onClickAddNewDocumento() {
    this.onAddNewDocumento();
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  onAddNewDocumento() {
    if (!this.formDocument.valid) {
      return;
    }

    this.loadingController
      .create({
        message: '',
        keyboardClose: true,
      })
      .then((loadingEl) => {
        loadingEl.present();
        this.generalProvider.subirDocumento(this.newDocumento, this.budgetProvider.budget.id, this.subtipo.identificador, this.fileName).subscribe((data) => {
          if (!checkResponseCode(data['codigo'])) {
            loadingEl.dismiss().then(() => toastMessage(data["mensaje"]));
          } else {

            this.generalProvider.obtenerDocumentacion(this.budgetProvider.budget.id).subscribe((data) => {
              if (!checkResponseCode(data['codigo'])) {
                loadingEl.dismiss().then(() => toastMessage(data["mensaje"]));
              } else {
                this.documentsBudget = data['object'];
                loadingEl.dismiss().then(() => this.isNewDocument = false);
              }
            }, (err) => {
              loadingEl.dismiss().then(() => toastMessage());
            });
          }
        }, (err) => {
          loadingEl.dismiss().then(() => toastMessage());
        });
      });
  }

  loadFileFromDevice(event) {


    this.newDocumento = event.target.files[0];
    this.fileName = event.target.files[0].name;
    const reader = new FileReader();
    reader.readAsArrayBuffer(this.newDocumento);
    reader.onload = () => {
      // obtiene el blob del archivo
      this.blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))]);
      // crea la blobURL que podemos usar en el elemento imagen
      let blobURL: string = URL.createObjectURL(this.blob);

    };
    reader.onerror = (error) => {

      //todo toast con error?
      console.log(error);

    };
  };

  ngOnDestroy(): void {
    if (this.documentsSub) {
      this.documentsSub.unsubscribe();
    }
  }

  loadDocs() {
    this.loadingController
      .create({
        message: '',
        keyboardClose: true,
      })
      .then((loadingEl) => {
        loadingEl.present();
        this.generalProvider.obtenerDocumentacion(this.budgetProvider.budget.id).subscribe((data) => {
          if (!checkResponseCode(data['codigo'])) {
            loadingEl.dismiss().then(() => toastMessage(data["mensaje"]));
          } else {
            loadingEl.dismiss().then(() => this.documentsBudget = data['object']);
          }
        }, (err) => {
          loadingEl.dismiss().then(() => toastMessage());
        });
      });
  }

  getSubTipoDocumento(idDoc) {
    this.loadingController
      .create({
        message: '',
        keyboardClose: true,
      }).then((loadingEl) => {
      loadingEl.present();
      this.generalProvider.obtenerSubTipoDocumentacion(idDoc).subscribe(data => {
        if (data['codigo'] != 200) {
          loadingEl.dismiss().then(() => toastMessage(data['mensaje']));
        } else {
          this.subtipos = data['object'];
          loadingEl.dismiss();
        }
      }, err => {
        loadingEl.dismiss().then(() => toastMessage());
      })
    });
  }

  onClickDeleteDocument(doc, i, slidingItem) {
    slidingItem.close();
    this.isTrashcanCliked = true;
    this.indexDoc = i;
    this.docSelected = doc;
  }

  deleteDocument() {

    let idUser = localStorage.getItem('idUser');
    let idClienteAplicacion = localStorage.getItem('idClienteAplicacion');

    this.loadingController
      .create({
        message: '',
        keyboardClose: true,
      }).then((loadingEl) => {
      loadingEl.present();
      this.generalProvider.eliminarDocumentacion(this.docSelected.id, idUser, idClienteAplicacion, 3, this.budgetProvider.budget.id).subscribe(data => {
        if (!checkResponseCode(data['codigo'])) {
          loadingEl.dismiss().then(() => toastMessage(data['mensaje']));
        } else {
          this.documentsBudget.splice(this.indexDoc, 1);
          loadingEl.dismiss();
          this.isTrashcanCliked = false;
        }
      }, err => {
        loadingEl.dismiss().then(() => toastMessage());
      })
    });
  }

  onClickDownloadDocument(doc) {

    let idUser = localStorage.getItem('idUser');

    this.loadingController
      .create({
        message: '',
        keyboardClose: true,
      }).then((loadingEl) => {
      loadingEl.present();
      this.generalProvider.descargarDocumentacion(doc.driveId ? doc.driveId : undefined, idUser, doc.id).subscribe(data => {
        if (!checkResponseCode(data['codigo'])) {
          loadingEl.dismiss().then(() => toastMessage(data['mensaje']));
        } else {

          let file = data["object"];
          this.blob = this.b64toBlob(
            file.byteArray,
            file.mimeType,
            512
          );
          let pdf = URL.createObjectURL(
            this.blob
          );
          this.pdfUrl = this.sanitizer.bypassSecurityTrustUrl(pdf);
          let pdfResource = this.sanitizer.bypassSecurityTrustResourceUrl(pdf);


 /*         let element = document.createElement('a');
          element.setAttribute('href', this.pdfUrl.changingThisBreaksApplicationSecurity);
          element.setAttribute('download', file.fileName);

          element.style.display = 'none';
          document.body.appendChild(element);

          loadingEl.dismiss().then(() => {
            element.click();
            document.body.removeChild(element);
          });  */

          if (!this.platform.is('ios') && !this.platform.is('android')) {
            let element = document.createElement('a');
            element.setAttribute('href', this.pdfUrl.changingThisBreaksApplicationSecurity);
            element.setAttribute('download', file.fileName);

            element.style.display = 'none';
            document.body.appendChild(element);

            loadingEl.dismiss().then(() => {
              element.click();
              document.body.removeChild(element);
            });
          } else {


  /*          const downloadPath = (
              this.platform.is('android')
            ) ? this.file.externalDataDirectory : this.file.documentsDirectory;  */
            loadingEl.dismiss();

            const fileTransfer: FileTransferObject = this.transfer.create();
            fileTransfer.download(this.pdfUrl.changingThisBreaksApplicationSecurity, this.file.dataDirectory + file.fileName).then((entry) => {
              console.log('download complete: ' + entry.toURL());
            }, (error) => {
              // handle error
            });

          }


        }
      }, err => {
        loadingEl.dismiss().then(() => toastMessage());
      })
    });
  }

  bytesToSize(bytes, decimals = 0) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

}
