import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiProvider} from '../api/api';

@Injectable({
  providedIn: 'root'
})
export class ContactsProvider {

  constructor(
    public http: HttpClient,
    public apiProvider: ApiProvider,
  ) { }

  getContactos(page: number) {
    return this.apiProvider.get('contactoWS/obtenerContactos', {
        params: {
            'numPag': page,
            'numElem': 15,
            'contactosDesdeLog': false
        }
    });
  }
  getClientContacts(id: number) {
    return this.apiProvider.get('contactoWS/obtenerContactos', {
        params: {
            'idCliente': id,
            'numPag': 1,
            'numElem': 15,
            'contactosDesdeLog': false
        }
    });
  }

}
