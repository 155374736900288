// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleMapsAPIKey: 'AIzaSyBgURtl9Pn097-cc4q91WIRDEiTZNcq7gA',
  // beyondUpAPIKey: '0c805ec7-75c3-4b70-bf03-4a1c14d677fa', // DESARROLLO NUEVA
 // beyondUpAPIKey: '08fe1122-38ad-488b-b38e-38ee52d25524', // PRE
  // beyondUpAPIKey: '693ecb27-863a-4522-a38a-34a5afef81f0',
  beyondUpAPIKey: '856a25b6-339a-4e9a-98e4-7c3d8f677f69', // PRO DE EUROPA
  // beyondUpAPIKey : 'a09114c6-8073-40d1-9a84-99c37b3da82f', // PRO DE CHILE ACTUAL
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
