import { Component, Input, OnInit } from '@angular/core';
import {AlertController, ModalController, PopoverController} from '@ionic/angular';
import { ClientNewBudgetAnnotationsModalComponent } from 'src/app/modals/client-new-budget-annotations-modal/client-new-budget-annotations-modal.component';
import { ClientNewBudgetDiscountsModalComponent } from 'src/app/modals/client-new-budget-discounts-modal/client-new-budget-discounts-modal.component';
import { ClientNewBudgetDocumentsModalComponent } from 'src/app/modals/client-new-budget-documents-modal/client-new-budget-documents-modal.component';
import { ClientNewBudgetMethodPaymentModalComponent } from 'src/app/modals/client-new-budget-method-payment-modal/client-new-budget-method-payment-modal.component';
import { ClientNewBudgetPointsModalComponent } from 'src/app/modals/client-new-budget-points-modal/client-new-budget-points-modal.component';
import {BudgetProvider} from "../../providers/budget/budget";
import {ItemsService} from "../../providers/items.service";

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  @Input() isSummary;
  @Input() item;
  @Input() itemCantidad;
  @Input() client;
  @Input() budget;
  @Input() itemDiscounts;

  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController,
    private budgetProvider: BudgetProvider,
    private alertCtrl: AlertController,
    private itemsService: ItemsService
  ) {}

  ngOnInit() {}

  async  onClickItemDiscounts() {
    if (this.isSummary && this.budgetProvider.multipleTax) {
      let alert = await this.alertCtrl.create({
        header: 'Atención',
        message: 'No se pueden añadir descuentos generales con múltiples impuestos',
        buttons: [
          {
            text: 'Aceptar',
          },
        ],
      });

      await alert.present();

    } else {
      //   this.modalController.dismiss();
      //Abrir modal descuentos
           this.onOpenDiscountsModal(
             this.item,
             this.itemCantidad,
             this.client,
             this.budget,
             this.itemDiscounts,
             this.isSummary
           );
      this.popoverController.dismiss();
    }
  }

  async  onClickDiscounts() {
    if (this.isSummary && this.budgetProvider.multipleTax) {
      let alert = await this.alertCtrl.create({
        header: 'Atención',
        message: 'No se pueden añadir descuentos generales con múltiples impuestos',
        buttons: [
          {
            text: 'Aceptar',
          },
        ],
      });

      await alert.present();

    } else {
      this.popoverController.dismiss(1);
    }
  }

  onClickMethodPayment() {
    //Abrir modal forma de pago
    this.onOpenMethodPaymentModal();
    this.popoverController.dismiss();
  }

  onClickAnnotations(isInternal) {
    this.onOpenAnnotationsModal(isInternal);
    this.popoverController.dismiss();
  }

  onClickPoints() {
    this.onOpenPointsModal();
    this.popoverController.dismiss();
  }

  onClickDocuments() {
    this.onOpenDocumentsModal();
    this.popoverController.dismiss();
  }

  onOpenDiscountsModal(item, itemCantidad, client, budget, itemDiscounts, isSummary) {
    this.modalController
      .create({
        component: ClientNewBudgetDiscountsModalComponent,
        componentProps: {
          isSummary,
          item,
          itemCantidad,
          client,
          budget,
          itemDiscounts,
        },
      })
      .then((modalEl) => {
        modalEl.present();
      });
  }

  onOpenMethodPaymentModal() {
    this.modalController
      .create({
        component: ClientNewBudgetMethodPaymentModalComponent,
        componentProps: {},
      })
      .then((modalEl) => {
        modalEl.present();
      });
  }

  onOpenAnnotationsModal(isInternal) {
    this.modalController
      .create({
        component: ClientNewBudgetAnnotationsModalComponent,
        componentProps: {
          isInternal,
          client: this.client
        },
      })
      .then((modalEl) => {
        modalEl.present();
      });
  }

  onOpenPointsModal() {
    this.modalController
      .create({
        component: ClientNewBudgetPointsModalComponent,
        componentProps: {},
      })
      .then((modalEl) => {
        modalEl.present();
      });
  }

  onOpenDocumentsModal() {
    this.modalController
      .create({
        component: ClientNewBudgetDocumentsModalComponent,
        componentProps: {},
      })
      .then((modalEl) => {
        modalEl.present();
      });
  }
}
