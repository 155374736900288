import {Component, Input, OnInit} from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
  ToastController
} from '@ionic/angular';
import {BudgetProvider} from '../../providers/budget/budget';
import {Budget} from '../../providers/budget/budget.model';
import {GeneralProvider} from '../../providers/general/general';
import {SignModalComponent} from '../sign-modal/sign-modal.component';
import {checkResponseCode, toastMessage} from "../../providers/utils/utils";
import {SendBudgetEmailModalComponent} from "../send-budget-email-modal/send-budget-email-modal.component";
import {SocialSharing} from "@awesome-cordova-plugins/social-sharing/ngx";


@Component({
  selector: 'app-client-new-budget-successfully-saved-budget-modal',
  templateUrl: './client-new-budget-successfully-saved-budget-modal.component.html',
  styleUrls: ['./client-new-budget-successfully-saved-budget-modal.component.scss'],
})
export class ClientNewBudgetSuccessfullySavedBudgetModalComponent implements OnInit {
  @Input() newBudget: any;
  budgets: Budget[];
  idsDocGeneral = [];
  docsGeneral = [];
  camposFirma = [];
  messageSinContacto = 'Hay un problema con la configuración del contacto fiscal, debes cualificarlo para poder firmar el presupuesto';
  messageSinDoc = 'Alguien de tu empresa olvidó configurar esta parte. Por favor, contacta con tu administrador';
  messageSigned = 'El presupuesto ya está firmado';
  messageNoSign = 'No tiene habilitado el módulo Firma digital';
  messageNoSignState = 'No hay ningún estado configurado para la firma digital';
  isSignatureSuccessfull: boolean = false;
  isFirma: boolean;
  numPage: number = 1;
  // isFirmaSubscription: Subscription;
  navigator: any = window.navigator;
  states = [];
  isChangeState = false;
  savedMessage = '¡El presupuesto ha sido guardado correctamente!';
  file: any;

  constructor(
    private modalController: ModalController,
    private navController: NavController,
    private budgetProvider: BudgetProvider,
    private alertController: AlertController,
    private loadingCtrl: LoadingController,
    private generalProvider: GeneralProvider,
    private toastCtrl: ToastController,
    private socialSharing: SocialSharing,
    private platform: Platform
  ) {
  }

  ngOnInit() {
    this.budgetProvider.budgets.subscribe(budgets => {
      this.budgets = budgets
    })
  }

  clikedReturnToSearch() {
    this.navController.navigateBack('/search');
    this.modalController.dismiss();
  }

  onClickBudget(budget) {
    this.idsDocGeneral = [];
    this.docsGeneral = [];
    //TODO descomentar
    if (localStorage.getItem('isFirma') == 'false') {
      //if (false) {
      this.presentAlertNoDocumentErr(this.messageNoSign);
    } else {
      let sinContacto = false;
      if (budget.fechaFirma) {
        this.presentAlertNoDocumentErr(this.messageSigned);
      } else {
        if (!budget.contactoFiscal) {
          sinContacto = true;
        } else if (!budget.contactoFiscal.email) {
          sinContacto = true;
        }
        if (sinContacto) {
          this.presentAlertNoDocumentErr(this.messageSinContacto);
        } else {
          this.loadingCtrl.create({keyboardClose: true, message: ''})
            .then((loadingEl) => {
              loadingEl.present().then(() =>
                this.budgetProvider.hasDigitalSign(budget.id).subscribe((data: any) => {
                    if (!data['object']) {
                      this.loadingCtrl
                        .dismiss()
                        .then(() => this.presentAlertNoDocumentErr(this.messageNoSignState));
                    } else {
                      this.generalProvider.getDocumentacion(budget.id).subscribe(
                        (data: any[]) => {
                          let sinSubtipo = false;
                          for (let j = 0; j < data['object'].length; j++) {
                            if (data['object'][j].idSubtipo) {
                              this.idsDocGeneral.push(data['object'][j].id);
                              this.docsGeneral.push(data['object'][j]);
                            } else {
                              sinSubtipo = true;
                            }
                          }
                          if (sinSubtipo) {
                            this.loadingCtrl
                              .dismiss()
                              .then(() => this.presentAlertNoDocumentErr(this.messageSinDoc));
                          } else {
                            if (this.docsGeneral.length == 0) {
                              // si no hay docs adjuntos, pedimos al menos la firma del cliente para insertarla en el jasper de presupuesto
                              this.camposFirma = [];
                              this.loadingCtrl
                                .dismiss()
                                .then(() => this.openSignBudgetModal(budget));
                            } else {
                              this.generalProvider.obtenerCamposFirma(this.idsDocGeneral).subscribe(
                                (data: any[]) => {
                                  if (data['object'].length == 0) {

                                    // si no hay docs adjuntos, pedimos al menos la firma del cliente para insertarla en el jasper de presupuesto
                                    this.camposFirma = [];
                                    this.loadingCtrl
                                      .dismiss()
                                      .then(() => this.openSignBudgetModal(budget));


                                    // for (let k = 0; k < this.docsGeneral.length; k++) {
                                    //   let idsFinalDocs = [];
                                    //   idsFinalDocs.push(this.docsGeneral[k].id);
                                    //   this.generalProvider
                                    //     .insertarFirmaDocumento(idsFinalDocs, budget.id, [])
                                    //     .subscribe((data) => {
                                    //         if (!data) {
                                    //           this.loadingCtrl
                                    //             .dismiss()
                                    //             .then(() => errorToastMessage('', this.toastCtrl));
                                    //         } else {
                                    //           let arraybuf = data;
                                    //           let blob = new Blob([data as BlobPart], {
                                    //             type: 'application/pdf',
                                    //           });
                                    //
                                    //           setTimeout(() => {
                                    //             this.budgetProvider.sendDoc(budget.id, blob, this.docsGeneral[k].idSubtipo,
                                    //               this.docsGeneral[k].nombreFichero.replace('/', '_'))
                                    //               .subscribe(
                                    //                 (data: any[]) => {
                                    //                   if (k == this.docsGeneral.length - 1) {
                                    //                     this.budgetProvider.signBudgetSinDoc(budget.id)
                                    //                       .subscribe(
                                    //                         (data: any[]) => {
                                    //                           this.isSignatureSuccessfull = true;
                                    //                           this.numPage = 1;
                                    //                           this.loadingCtrl
                                    //                             .dismiss()
                                    //                             .then(() => setTimeout(() => {
                                    //                               this.isSignatureSuccessfull = false;
                                    //                               // está a 3 segundos para que se vea la imagen.
                                    //                             }, 3000));
                                    //                         },
                                    //                         (err) => {
                                    //                           console.log(err);
                                    //                         }
                                    //                       );
                                    //                   }
                                    //                 },
                                    //                 (err) => {
                                    //                   console.log(err);
                                    //                 }
                                    //               );
                                    //           }, 3500);
                                    //         }
                                    //       },
                                    //       (err) => {
                                    //         this.loadingCtrl
                                    //           .dismiss()
                                    //           .then(() => errorToastMessage('', this.toastCtrl));
                                    //         console.log('err', err);
                                    //       }
                                    //     );
                                    // }
                                  } else {
                                    this.camposFirma = data['object'];
                                    this.loadingCtrl
                                      .dismiss()
                                      .then(() => this.openSignBudgetModal(budget));
                                  }
                                },
                                (err) => {
                                  console.log(err);
                                }
                              );
                            }
                          }
                        },
                        (err) => {
                          console.log(err);
                        }
                      );
                    }
                  },
                  (err) => {
                    console.log(err);
                  })
              )
              ;
            });
        }
      }
    }
  }

  openSignBudgetModal(budget) {
    this.modalController
      .create({
        component: SignModalComponent,
        componentProps: {
          budget: budget,
          idsDocGeneral: this.idsDocGeneral,
          camposFirma: this.camposFirma,
          docsGeneral: this.docsGeneral
        },
      })
      .then((modalEl) => {
        modalEl.present().then(() => {
          modalEl.onDidDismiss()
            .then((data: any) => {
              if (data != undefined) {
                if (data.data.update) {
                  this.clikedReturnToSearch();
                }
              }
            });
        });
      });
  }

  async presentAlertNoDocumentErr(message) {
    const alert = await this.alertController.create({
      header: 'Upsss!!',
      message: message,
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {
            //      this.openSignBudgetModal(budget);
          }
        }
      ]
    });
    await alert.present();
  }

  onShare() {
    // Descargamos el presupuesto antes de compartirlo

    this.loadingCtrl.create({
      message: '',
      keyboardClose: true,
    }).then((loadingEl) => {
      loadingEl.present();
      this.budgetProvider.downloadBudget(this.newBudget.id).subscribe((data) => {

          let auxFile = new Blob([data as BlobPart], {
            type: 'application/pdf',
          });
          this.file = new File([auxFile as BlobPart], this.newBudget.numPresupuesto.replace('/', '_') + '.pdf', {type: 'application/pdf'});
          loadingEl.dismiss();

          if (!this.platform.is('ios') && !this.platform.is('android')) {

            if (this.navigator.canShare && this.navigator.canShare({files: [this.file]})) {
              this.navigator.share({
                files: [this.file],
                title: this.newBudget.numPresupuesto.replace('/','_'),
                text: '',
              })
                .then(() => console.log('Share was successful.'))
                .catch((error) => console.log('Sharing failed', error));
            } else {
              console.log(`Your system doesn't support sharing files.`);
            }

          } else {
            this.socialSharing.share('', this.newBudget.numPresupuesto.replace('/','_'), this.file).then(() => {
              // Success!
            }).catch(() => {
              // Error!
            });
          }

        },
        (err) => {
          loadingEl.dismiss().then(() => toastMessage());
        }
      )
    });
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  changeState() {
    this.loadingCtrl.create({
      message: '',
      keyboardClose: true,
    })
      .then((loadingEl) => {
        loadingEl.present();
        this.budgetProvider
          .getStates(this.newBudget.id)
          .subscribe(data => {
            if (!checkResponseCode(data['codigo'])) {
              loadingEl.dismiss().then(() => toastMessage(data['mensaje']));
            } else {
              this.states = data['object'];
              this.isChangeState = true;
              loadingEl.dismiss();
            }
          }, err =>
            loadingEl.dismiss().then(() => toastMessage()));
      })
  }

  fireChangeState(state) {
    if (state.firmaDigital) {
      this.onClickBudget(this.newBudget);
    } else {
      this.loadingCtrl.create({
        message: '',
        keyboardClose: true,
      })
        .then((loadingEl) => {
          loadingEl.present();
          this.budgetProvider
            .changeState(this.newBudget.id, state.id)
            .subscribe(data => {
              if (!checkResponseCode(data['codigo'])) {
                loadingEl.dismiss().then(() => toastMessage(data['mensaje']));
              } else {
                loadingEl.dismiss();
                this.isChangeState = false;
                this.savedMessage = '¡El estado del presupuesto ha cambiado correctamente!';
              }
            }, err =>
              loadingEl.dismiss().then(() => toastMessage()));
        })
    }
  }

  sendMailBudget() {
    this.modalController
      .create({
        component: SendBudgetEmailModalComponent,
        cssClass: 'app-send-budget-email-modal',
        componentProps: {},
      })
      .then((modalEl) => {
        modalEl.present().then(() => {
          modalEl.onDidDismiss()
            .then((data: any) => {
              if (data != undefined) {
                if (data.data.update) {
                  this.savedMessage = '¡Presupuesto enviado correctamente!';
                }
              }
            });
        });
      });
  }

}
