import * as Constants from './../../environments/environment';
import { Injectable, NgModule} from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@Injectable(
  { providedIn: 'root' }
)
export class HttpsRequestInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(localStorage.getItem('Authorization')) {
            const cloneReq2 = req.clone({headers: req.headers.set('x-api-key', Constants.environment.beyondUpAPIKey).set('Authorization', localStorage.getItem('Authorization'))});
            return next.handle(cloneReq2);
        } else {
            const cloneReq = req.clone({headers: req.headers.set('x-api-key', Constants.environment.beyondUpAPIKey)});
            return next.handle(cloneReq);
        }
    }
}

@NgModule({
 providers: [
 { provide: HTTP_INTERCEPTORS, useClass: HttpsRequestInterceptor, multi: true }
 ]
})
export class InterceptorModule { }
