import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'search',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'budgets',
    loadChildren: () =>
      import('./budgets/budgets.module').then((m) => m.BudgetsPageModule),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('../app/pages/search/search.module').then(
        (m) => m.SearchPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'client',
    children: [
      {
        path: 'new-client',
        loadChildren: () =>
          import('./pages/client/new-client/new-client.module').then(
            (m) => m.NewClientPageModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: ':clienteId',
        loadChildren: () =>
          import('./pages/client/client-budget/client-budget.module').then(
            (m) => m.ClientBudgetPageModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: ':clienteId/client-detail',
        loadChildren: () =>
          import('./pages/client/client-detail/client-detail.module').then(
            (m) => m.ClientDetailPageModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: ':clienteId/client-delegations',
        loadChildren: () =>
          import(
            './pages/client/client-delegations/client-delegations.module'
          ).then((m) => m.ClientDelegationsPageModule),
        canLoad: [AuthGuard],
      },
      {
        path: ':clienteId/client-contacts',
        loadChildren: () =>
          import('./pages/client/client-contacts/client-contacts.module').then(
            (m) => m.ClientContactsPageModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: ':clienteId/new-budget',
        loadChildren: () => import('./pages/client/new-budget/new-budget.module').then( m => m.NewBudgetPageModule),
        canLoad: [AuthGuard],
      },
      {
        path: ':clienteId/new-budget/resume',
        loadChildren: () => import('./pages/client/new-budget/modals/new-budget-summary-modal/new-budget-summary-modal.module').then( m => m.NewBudgetSummaryModalComponentModule),
        canLoad: [AuthGuard],
      }
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
