import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { delay, take, tap } from 'rxjs/operators';
import { Documento } from './document.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  private _documents = new BehaviorSubject<Documento[]>([
    new Documento(
      0,
      'Contrato_BU.pdf',
      'Adjuntos',
      'Dossier producto',
      '1.5MB',
      '12/12/2021'
    ),
    new Documento(
      1,
      '03178954.jpg',
      'Contratos',
      'Beyond up',
      '0.5MB',
      '07/12/2021'
    ),
    new Documento(
      2,
      '58795874445896.jpg',
      'Gastos',
      'Factura proveedor',
      '1.3MB',
      '06/09/2021'
    ),
  ]);

  get documents() {
    return this._documents.asObservable();
  }

  constructor(private loadingController: LoadingController) {}

  addDocument(
    name: string,
    type: string,
    subtype: string,
    size: string,
    date: string
  ) {
    const newDocument = new Documento(
      +Math.random().toString(),
      name,
      type,
      subtype,
      size,
      date
    );
    return this.documents.pipe(
      take(1),
      delay(2000),
      tap((documents) => {
        this._documents.next(documents.concat(newDocument));
        this.loadingController.dismiss();
      })
    );
  }
}
