export class Documento {
  constructor(
    public id: number,
    public name: string,
    public type: string,
    public subtype: string,
    public size: string,
    public date: string,
  ) {}
}
