/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { ApiProvider } from '../providers/api/api';
import * as Constants from 'src/environments/environment';
import * as sha1 from 'sha.js';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from './user.model';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // private _userIsAuthenticated = false;
  private _user = new BehaviorSubject<User>(null);

  headers: {};
  params: {};
  to64: string;
  authorization: string;

  // Comprobar si tengo valores en el Store
  autoLogin() {
    return from(Storage.get({key: 'authData'})).pipe(
      map(storedData => {
        if (!storedData || !storedData.value) {
          return null;
        }
        const parseData = JSON.parse(storedData.value) as {
          userId: string;
          email: string;
          name: string;
          authorization: string;
          tieneFirmaDigital: Observable<boolean>;
          empDato: any[];
          idClienteAplicacion: string
        };
        const user = new User(
          parseData.userId,
          parseData.email,
          parseData.name,
          parseData.authorization,
          parseData.tieneFirmaDigital,
          parseData.empDato,
          parseData.idClienteAplicacion
          );
          return user;
      }),
      tap(user => {
        if (user) {
          this._user.next(user);
        }
      }),
      map(user => {
        return !!user;
      })
    );
  }

  get userIsAuthenticated() {
    return this._user.asObservable().pipe(map(user => {
      if (user) {
        return !!user.authorization;
      } else {
        return false;
      }
     }));
  }

  get userName() {
    return this._user.asObservable().pipe(map(user => {
     if (user) {
       return user.name;
     } else {
       return null;
     }
    }));
  }

  get userIsFirma() {
    return this._user.asObservable().pipe(map(user => {
      if (user) {
        return !!user.tieneFirmaDigital;
      } else {
        return false;
      }
     }));
  }

  get empDato() {
    return this._user.asObservable().pipe(map(user => {
      if (user) {
        return user.empDato;
      } else {
        return null;
      }
     }));
  }

  constructor(private apiProvider: ApiProvider) {}

  login(username: string, password: string) {
    // this._userIsAuthenticated = true;
    this.to64 = Buffer.from(
      username + ':' + sha1('sha1').update(password).digest('hex')
    ).toString('base64');
    this.authorization = 'Basic ' + this.to64;
    this.headers = {
      Authorization: this.authorization,
      'x-api-key': Constants.environment.beyondUpAPIKey,
    };
    return this.apiProvider.post('usuarioWS/login', '', {
      headers: this.headers,
    }).pipe(tap(this.setUserData.bind(this)));
  }

  logout() {
    // this._userIsAuthenticated = false;
    this._user.next(null);
  }

  private setUserData(data) {
    if (data['codigo'] == 200) {
      const userData = data.object;
      userData.authorization = this.authorization;
      userData.name = userData.apellido1 + (userData.apellido2? ' ' + userData.apellido2 : '') + ', ' + userData.nombre;
      localStorage.setItem('userName', userData.name);

      this._user.next(
        new User(
          userData.id,
          userData.email,
          userData.name,
          userData.authorization,
          userData.tieneFirmaDigital,
          userData.empDato[0].id,
          userData.idClienteAplicacion
        )
      );
      this.storeAuthData(
        userData.id,
        userData.email,
        userData.name,
        userData.authorization,
        userData.tieneFirmaDigital,
        userData.empDato[0].id,
        userData.idClienteAplicacion
      );
    }
  }

  private storeAuthData(
    userId: string,
    email: string,
    name: string,
    authorization: string,
    tieneFirmaDigital: boolean,
    empDato: any[],
    idClienteAplicacion: string
    ) {
      const data = JSON.stringify({userId, email, name, authorization, tieneFirmaDigital, empDato, idClienteAplicacion});
      Storage.set({key: 'authData', value: data});
  }

}
