import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {IonItemSliding, LoadingController, ModalController, ToastController} from '@ionic/angular';
import {BudgetProvider} from 'src/app/providers/budget/budget';
import {InternalAnnotationsService} from 'src/app/providers/internal-annotations.service';

@Component({
  selector: 'app-client-new-budget-annotations-modal',
  templateUrl: './client-new-budget-annotations-modal.component.html',
  styleUrls: ['./client-new-budget-annotations-modal.component.scss'],
})
export class ClientNewBudgetAnnotationsModalComponent implements OnInit {
  @Input() isInternal;
  @Input() budgetId;
  @Input() client;
  isNewAnnotation: boolean = false;
  hasFinisedLoading: boolean = true;
  formBudgetAnnotations: FormGroup;
  formInternalAnnotations: FormGroup;
  internalAnnotations = [];
  observaciones: string = '';
  budget;
  isTrashcanCliked: boolean = false;
  itemId = 0;

  constructor(
    private modalController: ModalController,
    private loadingController: LoadingController,
    private budgetProvider: BudgetProvider,
    private toastController: ToastController,
    private internalAnnotationsService: InternalAnnotationsService
  ) {}

  ngOnInit() {

    // Inicializamos el formulario de anotaciones internas
    this.formInternalAnnotations = new FormGroup({
      anotacion: new FormControl(
        null,
        {
          updateOn: 'change',
          validators: [Validators.required]
        }
      ),
    });

    this.internalAnnotationsService.observaciones.subscribe(observaciones => {
      this.observaciones = observaciones
    });
    this.formBudgetAnnotations = new FormGroup({
      anotacion: new FormControl(
        this.budgetProvider.observations ? this.budgetProvider.observations : '',
        {
          updateOn: 'change',
          validators: [Validators.required]
        }
      ),
    })
  }

  onAddNewInternalAnnotation() {
    this.isNewAnnotation = true;
  }

  onClickSaveAnnotations() {
    if (this.isInternal) {
      this.onCreateInternalAnnotation();
    } else {
      this.onSaveObservation()
    }
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  onCreateInternalAnnotation() {
    if (!this.formInternalAnnotations.valid) {
      return
    } else {

      this.budgetProvider.annotations.push({observaciones: this.formInternalAnnotations.value.anotacion});
      this.formInternalAnnotations.reset();
      this.isNewAnnotation = false;
    }
  }

  onSaveObservation() {
    if (!this.formBudgetAnnotations.valid) {
      return
    } else {
      this.budgetProvider.observations = this.formBudgetAnnotations.value.anotacion;
      this.internalAnnotationsService
        .addObservaciones(this.formBudgetAnnotations.value.anotacion)
        .subscribe(() => {
          this.isNewAnnotation = false;
          this.onDismiss();
        });
    }
  }

  onClickDeleteAnnotation(annotation, slidingEl: IonItemSliding, index) {
    slidingEl.close();
    this.isTrashcanCliked = true;
    this.itemId = index;
//    })
  }

  clickedButton(str: string) {
    if (str == 'no') {
      this.isTrashcanCliked = false;
    } else {
      if (this.budgetProvider.annotations[this.itemId].id) {
        this.budgetProvider.annotations[this.itemId].desactivar = true;
      } else {
        this.budgetProvider.annotations.splice(this.itemId, 1);
      }

      this.isTrashcanCliked = false;
    }
  }

  toastError(msg) {
    this.toastController
      .create({
        message: msg,
        duration: 1500,
        position: 'bottom',
      })
      .then((toastEl) => {
        toastEl.present();
      });
  }

}
