import { Observable } from "rxjs";

export class User {

  constructor(
    public id: string,
    public email: string,
    public name: string,
    public authorization: string,
    public tieneFirmaDigital: Observable<boolean>,
    public empDato: any[],
    public idClienteAplicacion: string
   ) {}

  // get token() {
  //   if (!this.tokenExpirationDate || this.tokenExpirationDate <= new Date()) {
  //     return null;
  //   }
  //   return this._token;
  // }

}
