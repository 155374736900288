import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  IonItemSliding,
  LoadingController,
  ModalController,
  NavController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {PopoverComponent} from 'src/app/components/popover/popover.component';
import {Client} from 'src/app/pages/client/client.model';
import {BudgetProvider} from 'src/app/providers/budget/budget';
import {ClientProvider} from 'src/app/providers/client/client';
import {Discount} from 'src/app/providers/discount.model';
import {DiscountsService} from 'src/app/providers/discounts.service';
import {Item} from 'src/app/providers/item.model';
import {ItemsService} from 'src/app/providers/items.service';
import {round, thousandSeparate} from 'src/app/providers/utils/utils';

// Factory function
const getEmptyDiscount = (): Discount => {
  return {
    id: null,
    descripcion: '',
    valor: null,
    tipo: false,
    editable: false,
    valorMaximo: false,
    desactivar: false,
    aplicaTrasImpuestos: false
  };
};

@Component({
  selector: 'app-client-new-budget-item-modal',
  templateUrl: './client-new-budget-item-modal.component.html',
  styleUrls: ['./client-new-budget-item-modal.component.scss'],
})
export class ClientNewBudgetItemModalComponent implements OnInit, OnDestroy {
  @Input() item: Item;
  @Input() budget;
  @Input() itemCantidad: number;
  @Input() descuento: Discount = getEmptyDiscount();
  @Input() itemDiscounts;
  client: Client;
  clientSub: Subscription;
  descuentoTotal: number;
  loadedDiscounts: Discount[];
  discountsSub: Subscription;
  numImporte: number;
  numIva: number;
  numTotal: number;
  isTrashcanCliked: boolean = false;
  discountId: number;

  public round = round;
  public thousandSeparate = thousandSeparate;

  constructor(
    private modalController: ModalController,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private popoverController: PopoverController,
    private discountsService: DiscountsService,
    private navController: NavController,
    private route: ActivatedRoute,
    private clientProvider: ClientProvider,
    private itemsService: ItemsService,
    private budgetProvider: BudgetProvider,
  ) {
  }

  ngOnInit() {
/*    this.route.paramMap.subscribe((paramMap) => {
      if (!paramMap.has('clienteId')) {
        this.navController.navigateBack('/search');
        return;
      }
      this.clientSub = this.clientProvider
        .getClient(+paramMap.get('clienteId'))
        .subscribe((client) => {
          this.client = client;
        });
    });
    if (this.client) {
      console.log('el cliente es ', this.client);
    }
 */
    this.discountsSub = this.discountsService.discounts.subscribe(
      (discounts) => {
        this.loadedDiscounts = discounts;
      }
    );

    if (this.item.descuentos && this.item.descuentos.length > 0) {
      this.descuentoTotal = this.item.descuentos.reduce((accumulator, object) => {
        return accumulator + object.valor;
      }, 0);
    } else {
      this.item.descuentos = [];
      this.item.descuentoTotal = 0;
    }

    // console.log({itemCantidad: JSON.parse(JSON.stringify(this.itemCantidad))});
  }

  ionViewWillEnter() {

  }


  onClickElipsis(ev) {
    this.createPopover(ev);
  }


  async createPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'popover',
      event: ev,
      translucent: true,
      componentProps: {
        isSummary: false,
        item: this.item,
        itemCantidad: this.itemCantidad,
        client: this.client,
        itemDiscounts: this.itemDiscounts,
      },
    });
    return await popover.present();
  }


  onAddItemToBudget() {
    if (this.evaluate(this.itemCantidad)) {
      return;
    }

    this.loadingController
      .create({
        message: '',
        keyboardClose: true,
      })
      .then((loadingEl) => {
        loadingEl.present();

        if (this.item.tipo == 'PACK') {
          this.item.elementos.forEach(e => {
            e.descuentos = [];
            e.descuentoTotal = 0;
            e.cantidad = this.itemCantidad * e.unidades;
            this.itemsService.elementos.push(e);
            this.itemsService.addItem(e);

          })
        } else {
          this.item.cantidad = this.itemCantidad;
          this.item.descuentoTotal = this.item.descuentos.reduce((accumulator, object) => {
            return accumulator + (object.desactivar? 0 : (object.tipo ? ((object.valor / 100) * (this.item.articulo.importe * this.item.cantidad)) : object.valor));
          }, 0);
          if (!this.itemsService.itemSelected) {
            this.itemsService.elementos.push(this.item);
            this.itemsService.addItem(this.item);
          }
          this.itemsService.itemSelected = false;
        }

        this.budgetProvider.recalculate();

        loadingEl.dismiss();
        this.modalController.dismiss();
        this.onOpenNewBudgetSummaryModal(this.client);
      });
    this.discountsService.clearDiscounts().subscribe((discounts) => {
      this.loadedDiscounts = discounts;
    });
    //  this.onDismiss();
  }

  onClickRemove() {
    this.itemCantidad == 1 ? this.itemCantidad : this.itemCantidad--;
  }

  onClickAdd() {
    this.itemCantidad++;
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  onOpenNewBudgetSummaryModal(client) {

    this.navController.navigateForward(`client/${this.budgetProvider.client.id}/new-budget/resume`);



/*    console.log('Opening Modal Summary');
    this.modalController
      .create({
        component: NewBudgetSummaryModalComponent,
        componentProps: {
          client,
        },
      })
      .then((modalEl) => {
        modalEl.present();
      });  */
  }

  onClickDeleteDiscount(discountId: number, slidingEl: IonItemSliding) {
    slidingEl.close();
    this.isTrashcanCliked = true;
    this.discountId = discountId;
  }

  clickedButton(str: string) {
    if (str == 'no') {
      this.isTrashcanCliked = false;
    } else {
      //borrar el descuento
      //   this.discountsService.deleteDiscount(this.discountId).subscribe();
      //   console.log('clicked delete yes on discount: ', this.discountId);
      if (this.item.descuentos[this.discountId].id) {
        this.item.descuentos[this.discountId].desactivar = true;
      } else {
        this.item.descuentos.splice(this.discountId, 1);
      }

      //cambiamos la ventana de pregunta eliminar descuento
      this.isTrashcanCliked = false;

      //volvemos a calcular el descuento total
      this.item.descuentoTotal = this.item.descuentos.reduce((accumulator, object) => {
        return accumulator + (object.desactivar? 0 : (object.tipo ? ((object.valor / 100) * (this.item.articulo.importe * this.itemCantidad)) : object.valor));
      }, 0);
    }
  }

  toastError(msg) {
    this.toastController
      .create({
        message: msg,
        duration: 1500,
        position: 'bottom',
      })
      .then((toastEl) => {
        toastEl.present();
      });
  }

  ngOnDestroy(): void {
    if (this.discountsSub) {
      this.loadedDiscounts = [];
      this.discountsSub.unsubscribe();
    }
    if (this.clientSub) {
      this.clientSub.unsubscribe();
    }
  }

  calculateTax(item) {
    return item.elementos.reduce((accumulator, object) => {
      return accumulator + ((object.articulo.importe * (object.articulo.impuesto.valor / 100)) * object.unidades);
    }, 0)
  }

  calculatePoints(item) {
    return item.elementos.reduce((accumulator, object) => {
      return accumulator + (object.articulo.valor * object.unidades);
    }, 0)
  }

  evaluate(itemCantidad) {
    return itemCantidad < 1 || isNaN(itemCantidad) || !Number.isInteger(itemCantidad);
  }

  getTotalDiscount(item) {
    return this.thousandSeparate(item.descuentos.reduce((accumulator, object) => {
      return accumulator + (object.desactivar? 0 : (object.tipo ? ((object.valor / 100) * (this.item.articulo.importe * this.itemCantidad)) : object.valor));
    }, 0));
  }
}
