import {Component, OnDestroy, OnInit} from '@angular/core';

import {Router} from '@angular/router';
import {AuthService} from './auth/auth.service';
import {MenuController} from "@ionic/angular";
import {User} from './auth/user.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {

  user: User;
  userName: string;
  private userNameSub: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    private menuCtrl: MenuController
    ) {}


  ngOnInit() {

    this.userNameSub = this.authService.userName.subscribe(userN => {
      this.userName = userN;
    });
  }

  onLogout() {
    localStorage.clear();
    this.authService.logout();
    this.menuCtrl.enable(false);
    this.router.navigateByUrl('/auth');
  }

  toggleTheme(event) {
    if (event.detail.checked) {
      document.body.setAttribute('color-theme', 'dark')
    } else {
      document.body.setAttribute('color-theme', 'light')
    }
  }

  back() {
    this.menuCtrl.close();
  }

  ngOnDestroy(): void {
      if (this.userNameSub) {
        this.userNameSub.unsubscribe()
      }
  }
}
