import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LoadingController, ModalController, ToastController} from '@ionic/angular';
import {ClientProvider} from 'src/app/providers/client/client';
import {parseClient} from "../../providers/models/parseClient";

@Component({
  selector: 'app-client-main-contact-data-modal',
  templateUrl: './client-main-contact-data-modal.component.html',
  styleUrls: ['./client-main-contact-data-modal.component.scss'],
})
export class ClientMainContactDataModalComponent implements OnInit {
  @Input() positions
  @Input() client
  @Input() tipoCliente
  @Input() contactoPrincipal
  @Input() formTaxAndDelegationDataValue
  @Input() localizacion: {}

  formClient;
  formContact: FormGroup;

  jobRequired = false;
  nameRequired = false;
  surname1Required = false;
  surname2Required = false;
  movilRequired = false;
  emailRequired = false;

  constructor(
    private modalController: ModalController,
    private router: Router,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private clientProvider: ClientProvider,
  ) {
  }

  ngOnInit() {

    this.surname1Required = this.clientProvider.qualificationContact.find(x => x.id == 22);
    this.surname2Required = this.clientProvider.qualificationContact.find(x => x.id == 23);
    this.movilRequired = this.clientProvider.qualificationContact.find(x => x.id == 25);
    this.emailRequired = this.clientProvider.qualificationContact.find(x => x.id == 26);
    this.jobRequired = this.clientProvider.qualificationContact.find(x => x.id == 28);

    this.formContact = new FormGroup({
      nombrePrincipal: new FormControl(
        null, {
          updateOn: 'change',
          validators: [Validators.required]
        }),
      apellido1Principal: new FormControl(
        null, {
          updateOn: 'change',
          validators: [this.surname1Required ? Validators.required : Validators.nullValidator]
        }
      ),
      apellido2Principal: new FormControl(
        '', {
          updateOn: 'change',
          validators: [this.surname2Required ? Validators.required : Validators.nullValidator]
        }
      ),
      sexoPrincipal: new FormControl(
        null, {
          updateOn: 'change',
          validators: [Validators.required]
        }
      ),
      movil: new FormControl(
        null, {
          updateOn: 'change',
          validators: [this.movilRequired ? Validators.required : Validators.nullValidator]
        }
      ),
      email: new FormControl(
        null, {
          updateOn: 'change',
          validators: [this.emailRequired ? Validators.required : Validators.nullValidator]
        }
      ),
      cargo: new FormControl(
        '', {
          updateOn: 'change',
          validators: [this.jobRequired ? Validators.required : Validators.nullValidator]
        }
      ),
      esPrincipal: new FormControl(
        true, {
          updateOn: 'change',
          validators: [Validators.required]
        }
      ),
      esFiscal: new FormControl(
        false, {
          updateOn: 'change',
          validators: [Validators.required]
        }
      ),
      esEnvio: new FormControl(
        false, {
          updateOn: 'change',
          validators: [Validators.required]
        }
      ),
    })
  }

  // usar si es no es cliente nuevo cuando se pida
  onSaveContact() {
    this.router.navigateByUrl('/search');
    this.onDismiss();
  }

  onAddClient() {
    this.formClient = {...this.formTaxAndDelegationDataValue, ...this.formContact.value}
    this.loadingController
      .create({
        message: '',
        keyboardClose: true,
      })
      .then((loadingEl) => {
        loadingEl.present();
        if (this.tipoCliente != 0) {
          let clientCompany = new parseClient().empresa(this.formClient, this.localizacion, this.formClient.sexoPrincipal);
          this.clientProvider.addClientCompany(clientCompany, this.formClient.pais).subscribe(data => {
            if (data['codigo'] != 200) {
              this.loadingController.dismiss()
                .then(() => this.toastController.create({
                  message: data['mensaje'],
                  duration: 1500,
                  position: 'bottom',
                }).then((toastEl) => {
                  toastEl.present();
                }))
            } else {
              this.loadingController.dismiss();
              this.router.navigateByUrl('/search');
              this.onDismiss();
            }
          }, err => {
            this.loadingController.dismiss()
              .then(() => this.toastController.create({
                message: err['message'],
                duration: 1500,
                position: 'bottom',
              }).then((toastEl) => {
                toastEl.present();
              }))
          });
        } else if (this.tipoCliente == 0) {
          let clientParticular = new parseClient().particular(this.formClient, this.localizacion, this.formClient.sexo);
          this.clientProvider.addClientParticular(clientParticular, this.formClient.pais).subscribe(data => {
            if (data['codigo'] != 200) {
              this.loadingController.dismiss()
                .then(() => this.toastController.create({
                  message: data['mensaje'],
                  duration: 1500,
                  position: 'bottom',
                }).then((toastEl) => {
                  toastEl.present();
                }))
            } else {
              this.loadingController.dismiss();
              this.router.navigateByUrl('/search');
              this.onDismiss();
            }
          }, err => {
            this.loadingController.dismiss()
              .then(() => this.toastController.create({
                message: err['message'],
                duration: 1500,
                position: 'bottom',
              }).then((toastEl) => {
                toastEl.present();
              }))
          });
        }
      });
  }

  onDismiss() {
    this.modalController.dismiss();
  }

}
