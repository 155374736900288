import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {ItemsService} from "../../providers/items.service";

@Component({
  selector: 'app-client-new-budget-points-modal',
  templateUrl: './client-new-budget-points-modal.component.html',
  styleUrls: ['./client-new-budget-points-modal.component.scss'],
})
export class ClientNewBudgetPointsModalComponent implements OnInit {

  totalPoints = 0;

  constructor(
    private modalController: ModalController,
    private itemsService: ItemsService
  ) { }

  ngOnInit() {
    //obtener la suma de los importes de cada item
    this.totalPoints = this.itemsService.elementos.reduce((accumulator, object) => {
      return accumulator + (object.articulo.valor * object.cantidad);
    }, 0);
  }

  onDismiss() {
    this.modalController.dismiss()
  }

}
