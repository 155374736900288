import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {ApiProvider} from '../api/api';
import { UserEmail } from './userEmail.model';

@Injectable({
  providedIn: 'root'
})
export class UserProvider {
  private _users = new BehaviorSubject<UserEmail[]>([]);
  fetchedUsers: UserEmail[] = [];
  usersMail: any = [];
  usersMailSelected: any = [];
  usersMailCC: any = [];
  usersMailSelectedCC: any = [];
  usersMailCCO: any = [];
  usersMailSelectedCCO: any = [];
  usersMailDTO = {
    contactos: [],
    usuarios: []
  };

  constructor(
    public http: HttpClient,
    public apiProvider: ApiProvider,
  ) { }

  fetchUsers(query: string, idCliente) {
    return this.apiProvider.get('presupuestos/mailsEnvio', {
        params: {
            'busqueda': query,
          'idCliente': idCliente
        }
    }).pipe(
      map((resData: any) => {
        if (resData['codigo'] !== '200') {
          return [];
    //      console.log(resData['mensaje'])
        } else {
         /* resData['object'].forEach(email => {
            this.fetchedUsers.push(
              new UserEmail(email)
            )
          });  */

         let result = [];
         if (resData['object'].length > 0) {
           resData['object'].forEach(x => {
             if (!this.usersMailSelected.find(u => (u.email == x.email))) {
               result.push(x);
             }
           })
         }

          return result;
        }
      }),
      tap((users) => {
        this._users.next(users)
      })
    );
  }

  get users() {
    return this._users.asObservable()
  }

}
