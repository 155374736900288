import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {BudgetProvider} from "../../providers/budget/budget";
import {thousandSeparate} from 'src/app/providers/utils/utils';


@Component({
  selector: 'app-budget-info-modal',
  templateUrl: './budget-info-modal.component.html',
  styleUrls: ['./budget-info-modal.component.scss'],
})
export class BudgetInfoModalComponent implements OnInit {
  @Input() budget: any;
  numeros: any = /[0-9]+./g;
  precision = 100;
  randomnum: string = "0.00 €";
  public thousandSeparate = thousandSeparate;

  constructor(
    private modalController: ModalController,
    private budgetProvider: BudgetProvider
  ) {
  }

  ionViewDidEnter() {

    if (this.budget && !this.budgetProvider.dataLoad) {

      this.budgetProvider.taxList = [];

      this.budget.presupuestoArticulos.forEach(x => {

        x.descuentoTotal = x.descuentos.reduce((accumulator, object) => {
          return accumulator + (object.tipo ? ((object.valor / 100) * (x.articulo.importe * x.cantidad)) : object.valor)
        }, 0);

        // Agrupamos los impuestos por valor
        let taxExist = this.budgetProvider.taxList.find(t => t.valor == x.articulo.impuesto.valor);
        if (taxExist) {
          taxExist.importe = taxExist.importe + (((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valor / 100));
          if (x.tipo != 'SERVICIO') {
            taxExist.importeRecargo = taxExist.importeRecargo + (((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valorRecargo / 100));
          }
        } else {

          if (x.tipo != 'SERVICIO') {
            this.budgetProvider.taxList.push({
              id: x.articulo.impuesto.id,
              descripcion: x.articulo.impuesto.descripcion,
              valor: x.articulo.impuesto.valor,
              importe: ((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valor / 100),
              valorRecargo: x.articulo.impuesto.valorRecargo,
              importeRecargo: ((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valorRecargo / 100)
            });
          } else {
            this.budgetProvider.taxList.push({
              id: x.articulo.impuesto.id,
              descripcion: x.articulo.impuesto.descripcion,
              valor: x.articulo.impuesto.valor,
              importe: ((x.articulo.importe * x.cantidad) - x.descuentoTotal) * (x.articulo.impuesto.valor / 100),
              valorRecargo: x.articulo.impuesto.valorRecargo,
              importeRecargo: 0
            });
          }

        }
      });

      // Comprobamos si hay múltiples impuestos
      if (this.budgetProvider.taxList.length == 1) {
        //obtener la suma de los importes de cada item
        this.budgetProvider.taxList[0].importe = this.budget.valorImpuesto.split('€')[0].replace(',', '.');
        this.budgetProvider.taxList[0].importeRecargo = this.budget.recargo.split('€')[0].replace(',', '.');
      }
    }
  }

  ngOnInit() {

  }

  onDismiss() {
    this.modalController.dismiss();
  }

  budgetEstadoClass(str) {
    switch (str) {
      case '4. Firmado':
        return 'green';
      case '1. Borrador':
        return 'red';
    }
  }

  aplicaRecargo() {
    if (!this.budget) {
      return false;
    }
    return this.budget.recargo.split('€')[0].replace(',', '.') > 0;
  }


}
