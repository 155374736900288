import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ClientContactsPageRoutingModule } from './client-contacts-routing.module';

import { ClientContactsPage } from './client-contacts.page';
import { ClientMainContactDataModalComponent } from 'src/app/modals/client-main-contact-data-modal/client-main-contact-data-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ClientContactsPageRoutingModule
  ],
  declarations: [ClientContactsPage, ClientMainContactDataModalComponent]
})
export class ClientContactsPageModule {}
