import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LoadingController, ModalController} from '@ionic/angular';
import {UserProvider} from 'src/app/providers/user/user';
import {UserEmail} from 'src/app/providers/user/userEmail.model';
import {BudgetProvider} from "../../providers/budget/budget";
import {checkResponseCode, toastMessage} from "../../providers/utils/utils";

@Component({
  selector: 'app-send-budget-email-modal',
  templateUrl: './send-budget-email-modal.component.html',
  styleUrls: ['./send-budget-email-modal.component.scss'],
})
export class SendBudgetEmailModalComponent implements OnInit {
  toggleCc: boolean = false;
  formSendEmailBudget: FormGroup;
  loadedUsers: UserEmail[] = null;
  selectedUsers: UserEmail[] = [];
  results: UserEmail[] = []
  placeHolderStr: string = 'Para'
  classRequired: string = 'required'
  noMargin: string = '';
  paraSearch = '';
  paraSearchCC = '';
  paraSearchCCO = '';
  noMarginCC: string = '';
  placeHolderStrCC: string = 'CC'
  noMarginCCO: string = '';
  placeHolderStrCCO: string = 'CCO'


  constructor(
    private modalController: ModalController,
    private loadingController: LoadingController,
    private userProvider: UserProvider,
    private budgetProvider: BudgetProvider
  ) {
  }

  ngOnInit() {

    this.userProvider.usersMail = [];
    this.userProvider.usersMailSelected = [];
    this.userProvider.usersMailCC = [];
    this.userProvider.usersMailSelectedCC = [];
    this.userProvider.usersMailCCO = [];
    this.userProvider.usersMailSelectedCCO = [];

    this.userProvider.usersMailDTO = {
      contactos: [],
      usuarios: []
    };

    this.userProvider.users.subscribe((data) => {
      this.loadedUsers = data;
    });

    this.formSendEmailBudget = new FormGroup({
      paraShow: new FormControl(null, {}),
      para: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required],
      }),
      cc: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.email],
      }),
      cco: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.email],
      }),
      asunto: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required],
      }),
      mensaje: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required],
      }),
    });

  }

  onSearchChange(event, option) {
    const query: string = event.detail.value;
    if (query.length > 0) {
      this.userProvider.fetchUsers(query, this.budgetProvider.budget.cliente.id).subscribe((data) => {
        if (data) {
          if (option == 1) {
            this.userProvider.usersMail = data;
          } else if (option == 2) {
            this.userProvider.usersMailCC = data;
          } else {
            this.userProvider.usersMailCCO = data;
          }
        } else {
          this.userProvider.usersMail = [];
          this.userProvider.usersMailCC = [];
          this.userProvider.usersMailCCO = [];
        }
      });
    } else {
      this.userProvider.usersMail = [];
      this.userProvider.usersMailCC = [];
      this.userProvider.usersMailCCO = [];

    }
  }

  onClickUserFromlist(user, option) {
    if (user.esContacto) {
      this.userProvider.usersMailDTO.contactos.push(user);
    } else {
      this.userProvider.usersMailDTO.usuarios.push(user);
    }

    if (option == 1) {
      this.userProvider.usersMailSelected.push(user);
      this.paraSearch = '';
      this.placeHolderStr = '';
    } else if (option == 2) {
      this.userProvider.usersMailSelectedCC.push(user);
      this.paraSearchCC = '';
      this.placeHolderStrCC = '';
    } else {
      this.userProvider.usersMailSelectedCCO.push(user);
      this.paraSearchCCO = '';
      this.placeHolderStrCCO = '';
    }

    this.userProvider.usersMail = [];
    this.userProvider.usersMailCC = [];
    this.userProvider.usersMailCCO = [];

    if (this.userProvider.usersMailSelected.length > 0) {
      this.classRequired = '';
      this.noMargin = 'populated';
    }

    if (this.userProvider.usersMailSelectedCC.length > 0) {
      this.classRequired = '';
      this.noMarginCC = 'populated'
    }

    if (this.userProvider.usersMailSelectedCCO.length > 0) {
      this.classRequired = '';
      this.noMarginCCO = 'populated';
    }
  }

  onToggleCc() {
    this.toggleCc = !this.toggleCc;
  }

  onClickSendEmail() {
    this.loadingController
      .create({
        message: 'Enviando documentos...',
        spinner: 'crescent',
      })
      .then((loadingEl) => {
        loadingEl.present();
        this.budgetProvider.sendMail(this.budgetProvider.budget.id, this.userProvider.usersMailDTO).subscribe((data) => {
          if (!checkResponseCode(data['codigo'])) {
            loadingEl.dismiss().then(() => toastMessage(data["mensaje"]));
          } else {
            loadingEl.dismiss();
            this.modalController.dismiss({
              dismissed: true,
              update: true
            });
          }
        }, (err) => {
          loadingEl.dismiss().then(() => toastMessage());
        });
      });
  }

  onClickDeleteEmail(option, index, email) {
    if (option == 1) {
      this.userProvider.usersMailSelected.splice(index, 1);

      let userToDelete = this.userProvider.usersMailDTO.usuarios.find(u => (u.email == email));
      if (userToDelete) {
        this.userProvider.usersMailDTO.usuarios.splice(this.userProvider.usersMailDTO.usuarios.indexOf(userToDelete), 1);
      }

      let contactToDelete = this.userProvider.usersMailDTO.contactos.find(c => (c.email == email));
      if (contactToDelete) {
        this.userProvider.usersMailDTO.contactos.splice(this.userProvider.usersMailDTO.contactos.indexOf(contactToDelete), 1);
      }

    } else if (option == 2) {
      this.userProvider.usersMailSelectedCC.splice(index, 1);
    } else {
      this.userProvider.usersMailSelectedCCO.splice(index, 1);
    }

    if (this.userProvider.usersMailSelected.length == 0) {
      this.placeHolderStr = 'Para';
    }

    if (this.userProvider.usersMailSelectedCC.length == 0) {
      this.placeHolderStrCC = 'CC';
    }

    if (this.userProvider.usersMailSelectedCCO.length == 0) {
      this.placeHolderStrCCO = 'CCO';
    }
  }

  onDismiss() {
    this.modalController.dismiss();
  }
}
