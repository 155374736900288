import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'querystring';
import { BehaviorSubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Client } from 'src/app/pages/client/client.model';
import { ApiProvider } from '../api/api';
import { Storage } from '@capacitor/storage';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ClientProvider {
  // private _client = new BehaviorSubject<Client>(null);
  private _clients = new BehaviorSubject<Client[]>([]);
  tipoDoc = 'DNI';
  idTipoDoc = 1;

  qualificationContact = [];

  qualificationDelegation = [];

  qualificationParticular = [];

  qualificationCompany = [];

  constructor(
    public http: HttpClient,
    private apiProvider: ApiProvider,
    private loadingController: LoadingController
  ) {}

  getClient(id: number) {
    return this.clients.pipe(
      take(1),
      map((clients) => {
        return { ...clients.find((c) => c.id === id) };
      })
    );
  }

  getClientes(busqueda: string) {
    return this.apiProvider
      .get('presupuestos/clientes', {
        params: {
          busqueda
        },
      })
      .pipe(
        tap((clients: { codigo: string; mensaje: string; object: [] }) => {
          if (clients.object.length > 0) {
            this._clients.next(clients.object);
            this.loadingController.dismiss();
          } else {
            this.loadingController.dismiss();
            return;
          }
        })
      );
  }

  get clients() {
    return this._clients.asObservable();
  }

  getSegmentacion() {
    return this.apiProvider.get('clienteWS/obtenerDatosMaestrosSegmentacion');
  }

  getActivities(tipoCliente) {
    return this.apiProvider.get('clienteWS/obtenerActividades', {
      params: {
        tipoCliente: tipoCliente,
      },
    });
  }

  getCountries() {
    return this.apiProvider.get('clienteWS/obtenerPaises');
  }

  getPositions() {
    return this.apiProvider.get('clienteWS/obtenerCargos');
  }

  getRoadTypes() {
    return this.apiProvider.get('clienteWS/obtenerTipoVia');
  }

  getLocalizacion(cp: number, idPais: number) {
    return this.apiProvider.post('clienteWS/buscarCodigoPostal/' + idPais, {
      codigoPostal: cp,
    });
  }

  getProvincia(cp: number, idPais: number, localidad) {
    return this.apiProvider.post('clienteWS/buscarCodigoPostal/' + idPais, {
      codigoPostal: cp,
      valorCampo1: localidad,
    });
  }

  getDelegations(idCliente: number) {
    return this.apiProvider.get('clienteWS/obtenerDelegaciones', {
      params: {
        idCliente,
        'numPag': 1,
        'numElem': 15,
      },
    })
  }

  addClientCompany(post, idPais: number) {
    return this.apiProvider.post('clienteWS/altaClienteEmpresa/' + idPais, post);
  }

  addClientParticular(post, idPais: number) {
    return this.apiProvider.post('clienteWS/altaClienteParticular/' + idPais, post);
  }

  getQualification(option) {
    return this.apiProvider.get('clienteWS/cualificacion', {
      params: {
        'option': option
      }
    });
  }

}
