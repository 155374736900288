import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LoadingController, ModalController} from '@ionic/angular';
import {Client} from 'src/app/pages/client/client.model';
import {DiscountsService} from 'src/app/providers/discounts.service';

import {thousandSeparate, toastMessage} from 'src/app/providers/utils/utils';
import {ClientNewBudgetItemModalComponent} from '../client-new-budget-item-modal/client-new-budget-item-modal.component';
import {ItemsService} from "../../providers/items.service";
import {BudgetProvider} from "../../providers/budget/budget";
import {NewBudgetSummaryModalComponent} from "../../pages/client/new-budget/modals/new-budget-summary-modal/new-budget-summary-modal.component";
import {UtilProvider} from "../../providers/utils/util";

@Component({
  selector: 'app-client-new-budget-discounts-modal',
  templateUrl: './client-new-budget-discounts-modal.component.html',
  styleUrls: ['./client-new-budget-discounts-modal.component.scss'],
})
export class ClientNewBudgetDiscountsModalComponent implements OnInit {
  @Input() item;
  @Input() budget;
  @Input() itemCantidad: number;
  @Input() client: Client;
  @Input() itemDiscounts;
  @Input() isSummary = true;
  public thousandSeparate = thousandSeparate;
  isButtonClicked: boolean;
  formDescuento: FormGroup;
  valorInicial: number;
  selectedDiscount = null;
  totalPresupuesto = 0;

  constructor(
    private modalController: ModalController,
    private loadingController: LoadingController,
    private discountsService: DiscountsService,
    private itemsService: ItemsService,
    private budgetProvider: BudgetProvider,
    private utils: UtilProvider
  ) {
  }

  ngOnInit() {
    this.valorInicial = 0;

    //obtener la suma de los importes de cada item
    this.totalPresupuesto = this.itemsService.elementos.reduce((accumulator, object) => {
      return accumulator + (object.articulo.importe * object.cantidad);
    }, 0);

    if (this.isSummary) {
      this.getDiscounts();
    } else {
      this.getItemDiscounts();
    }

    this.formDescuento = new FormGroup({
      descuento: new FormControl(
        null
      ),
      valor: new FormControl(
        null, {
          updateOn: 'change',
          validators: [Validators.required, Validators.min(0.1)],
        }),
      tipo: new FormControl(
        null, {
          updateOn: 'change',
          validators: [Validators.required],
        }),
      editable: new FormControl(true),
    })

  }

  onClickAddDiscount() {
    if (!this.formDescuento.valid) {
      return
    } else {

      let descuentosTotales = this.itemsService.elementos.reduce((accumulator, object) => {
        return accumulator + object.descuentoTotal;
      }, 0);


      if (this.isSummary) {
        if ((this.formDescuento.value.tipo? ((this.formDescuento.value.valor/100)*(this.budgetProvider.taxBase-descuentosTotales)) : this.formDescuento.value.valor) > this.budgetProvider.subtotal) {
          this.utils.alert('Atención', '', 'El descuento a insertar no puede ser superior al importe del concepto.');
          return;
        }
      } else {
        if ((this.formDescuento.value.tipo? ((this.formDescuento.value.valor/100)*(this.item.articulo.importe*this.item.cantidad)) : this.formDescuento.value.valor) > (this.item.articulo.importe * this.item.cantidad - this.item.descuentoTotal)
          || (this.formDescuento.value.tipo? ((this.formDescuento.value.valor/100)*(this.item.articulo.importe*this.item.cantidad)) : this.formDescuento.value.valor) > this.budgetProvider.subtotal) {
          this.utils.alert('Atención', '', 'El descuento a insertar no puede ser superior al importe del concepto.');
          return;
        }
      }

      this.loadingController.create({
        message: '',
        keyboardClose: true
      })
        .then((loadingEl) => {
          loadingEl.present();

          if (this.isSummary) {

            this.itemsService.discounts.push({
              id: null,
              descripcion: this.formDescuento.value.descuento.descripcion,
              valor: this.formDescuento.value.valor,
              tipo: this.formDescuento.value.tipo,
              editable: this.formDescuento.value.editable,
              descuento: {id: this.formDescuento.value.descuento.descuento.id}
            });

            loadingEl.dismiss();
            this.formDescuento.reset();
            this.modalController.dismiss();
        /*    this.modalController
              .create({
                component: NewBudgetSummaryModalComponent,
                componentProps: {
                  client: this.client,
                },
              })
              .then((modalEl) => {
                modalEl.present();
              });  */

          } else {

            this.item.descuentos.push({
              id: null,
              descripcion: this.formDescuento.value.descuento.descripcion,
              valor: this.formDescuento.value.valor,
              tipo: this.formDescuento.value.tipo,
              editable: this.formDescuento.value.editable,
              descuento: {id: this.formDescuento.value.descuento.descuento.id}
            });

            this.item.descuentoTotal = this.item.descuentos.reduce((accumulator, object) => {
              return accumulator + (object.tipo? ((object.valor/100)*(this.item.articulo.importe*this.item.cantidad)) : object.valor);
            }, 0);

            loadingEl.dismiss();
            this.formDescuento.reset();
            this.modalController.dismiss();
       //     this.onOpenItemModal(this.item, this.itemCantidad, this.client, this.budget, this.itemDiscounts);

          }

        });
      // this.loadingController.dismiss()
    }
  }

  onOpenItemModal(item, itemCantidad, client, budget?, itemDiscounts?) {
    this.modalController.create({
      component: ClientNewBudgetItemModalComponent,
      componentProps: {
        item,
        itemCantidad,
        client,
        budget,
        itemDiscounts
      }
    }).then((modalEl) => {
      modalEl.present();
    })
  }

  // initializeForm() {
  //   this.formDescuento = new FormGroup({
  //     id: new FormControl(
  //       this.selectedDiscount ? this.selectedDiscount.id : null
  //       ),
  //     descripcion: new FormControl(
  //       this.selectedDiscount ? this.selectedDiscount.descripcion : null
  //       ),
  //     valor: new FormControl(
  //       this.selectedDiscount && this.selectedDiscount.editable == false ? this.selectedDiscount.valor : this.valorInicial.toFixed(2) , {
  //       updateOn: 'change',
  //       validators: [Validators.required, Validators.min(0.1)],
  //     }),
  //     tipo: new FormControl(
  //       this.selectedDiscount && this.selectedDiscount.editable === false ? false : true, {
  //       updateOn: 'change',
  //       validators: [Validators.required],
  //     }),
  //     editable: new FormControl(this.selectedDiscount ? this.selectedDiscount.editable : true),
  //   })
  // }

  // getSelectedDiscount(ev) {
  //   console.log(ev)
  //   let discountId = ev.detail.value
  //   this.selectedDiscount = this.itemDiscounts.filter(d => d.id !== discountId)
  // }

  getDiscounts() {
    this.loadingController.create({
      message: '',
      keyboardClose: true,
    })
      .then((loadingEl) => {
        loadingEl.present();
        let empDato = JSON.parse(localStorage.getItem('empDato'));
        this.budgetProvider
          .getDiscounts(
            empDato.id,
            this.budgetProvider.client? this.budgetProvider.client.id : this.budgetProvider.budget.cliente.id,
          ).subscribe(
          (data) => {
            if (data['codigo'] == '200') {
              this.itemDiscounts = data['object'];
              loadingEl.dismiss()
            } else {
              toastMessage(data['mensaje']);
              loadingEl.dismiss()
            }
          },
          (err) => {
            toastMessage(err.message)
          }
        )
      })
  }

  getItemDiscounts() {
    this.loadingController.create({
      message: '',
      keyboardClose: true
    })
      .then((loadingEl) => {
        loadingEl.present();
        let empDato = JSON.parse(localStorage.getItem('empDato'));
        this.budgetProvider
          .getItemDiscounts(
            empDato.id,
            this.budgetProvider.client? this.budgetProvider.client.id : this.budgetProvider.budget.cliente.id,
            this.item.articulo.id,
            this.item.tipo
          ).subscribe(
          (data) => {
            if (data['codigo'] == '200') {
              this.itemDiscounts = data['object'];
              loadingEl.dismiss()
            } else {
              toastMessage(data['mensaje']);
              loadingEl.dismiss()
            }
          },
          (err) => {
            toastMessage(err.message)
          }
        )
      })
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  selectDiscount(discount) {
    this.formDescuento.controls['tipo'].setValue(discount.tipo);
    this.formDescuento.controls['valor'].setValue(discount.valor);
  }

}
