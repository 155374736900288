import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController, NavController, NavParams,
  ToastController,
} from '@ionic/angular';
import {SignatureProvider} from 'src/app/providers/signature/signature';
import {SignaturePad} from 'angular2-signaturepad';
import {GeneralProvider} from 'src/app/providers/general/general';
import {
  checkStorageLimit,
  errorToastMessage,
} from 'src/app/providers/utils/utils';
import {DomSanitizer} from '@angular/platform-browser';
import {BudgetProvider} from '../../providers/budget/budget';
import {AuthService} from "../../auth/auth.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-sign-modal',
  templateUrl: './sign-modal.component.html',
  styleUrls: ['./sign-modal.component.scss'],
})
export class SignModalComponent implements OnInit {
  @ViewChild(SignaturePad) public signaturePad: SignaturePad;

  signatureClient: string;
  userName: string;
  idOferta: any;
  documentacionGeneral: any;
  idDocumentacionGeneral: any;
  backAction: any;
  camposFirma: any;
  campoFirmaSelected: any;
  arraybuf: any;
  blob: any;
  pdf: any;
  pdfURL: any;
  pdfResource: any;
  pdfDownloaded;
  firstStepCompleted = false;
  idTipoDocumento: any;
  idTipoDocumentoAudio: any;
  tiposDocumento: any;
  isSignEmpty: boolean = true;
  isSignatureSuccessfull: boolean = false;
  signaturePadOptions: Object = {
    minWidth: 2,
    canvasWidth: 317,
    canvasHeight: 400,
    backgroundColor: 'transparent',
    penColor: '#000000',
  };
  budget: any;
  signatureUser: any;
  clientSigned = false;
  user = '';
  docsGeneral: any;
  userNameSub: Subscription;

  constructor(
    private modalController: ModalController,
    private signatureProvider: SignatureProvider,
    private generalProvider: GeneralProvider,
    private loadingController: LoadingController,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private sanitizer: DomSanitizer,
    private navParams: NavParams,
    private budgetProvider: BudgetProvider,
    private navCtrl: NavController,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.userName = localStorage.getItem('userName');
    this.camposFirma = this.navParams.get('camposFirma');
    this.idDocumentacionGeneral = this.navParams.get('idsDocGeneral');
    this.budget = this.navParams.get('budget');
    this.docsGeneral = this.navParams.get('docsGeneral');
    this.user = localStorage.getItem('userName');
//    if (this.camposFirma[0].identificador == 144) {
//      this.user = this.budget.contactoFiscal.nombre + ' ' + this.budget.contactoFiscal.apellido1 + this.budget.contactoFiscal.apellido2? ' ' + this.budget.contactoFiscal.apellido2 : '';
//    } else {
//      this.userNameSub = this.authService.userName.subscribe(userN => {
//        this.user = userN;
//      });
//    }
  }

  onSignBudget() {
    this.signatureClient = 'false';
    this.signatureClient = this.signaturePad.toDataURL();
    localStorage.setItem(
      'signatureClient',
      this.signatureClient.replace(/^data:image\/(png|jpg);base64,/, '')
    );
    if (!this.isSignEmpty) {
      if (this.camposFirma.length > 1) {
        this.clientSigned = true;
        if (this.camposFirma[1].identificador == 144) {
          this.user = this.budget.cliente.razonSocial;
        } else {
          this.user = this.userName;
        }
      } else {
        this.signOffer();
      }
    }
  }

  onSignBudgetUser() {
    this.signatureUser = 'false';
    this.signatureUser = this.signaturePad.toDataURL();
    localStorage.setItem(
      'signatureUser',
      this.signatureUser.replace(/^data:image\/(png|jpg);base64,/, '')
    );
    if (!this.isSignEmpty) {
      this.signOffer();
    }
  }

  signOffer() {
    let listFirma: any;

    if (this.camposFirma.length == 0) {
      listFirma = [];
    }
    else if (this.camposFirma.length == 1) {
      listFirma = [{
        identificador: this.camposFirma[0].identificador,
        nombre: this.camposFirma[0].nombre,
        tipoFirma: this.camposFirma[0].tipoFirma,
        imagen: localStorage.getItem('signatureClient')
      }];
    } else {
      listFirma = [{
        identificador: this.camposFirma[0].identificador,
        nombre: this.camposFirma[0].nombre,
        tipoFirma: this.camposFirma[0].tipoFirma,
        imagen: localStorage.getItem('signatureClient')
      },
        {
          identificador: this.camposFirma[1].identificador,
          nombre: this.camposFirma[1].nombre,
          tipoFirma: this.camposFirma[1].tipoFirma,
          imagen: localStorage.getItem('signatureUser')
        }];
    }
    this.loadingController
      .create({
        keyboardClose: true,
        message: ''
      })
      .then((loadingEl) => {
        loadingEl.present().then(() => {

            // si no hay docs

            if (this.docsGeneral.length == 0) {
              this.budgetProvider.signBudgetSinDoc(this.budget.id, {imagen: localStorage.getItem('signatureClient')})
                .subscribe(
                  (data: any[]) => {
                    this.isSignatureSuccessfull = true;
                    this.loadingController
                      .dismiss()
                      .then(() => setTimeout(() => {
                        this.isSignatureSuccessfull = false;
                        this.onDismiss();
                        // está a 3 segundos para que se vea la imagen.
                      }, 3000));
                  },
                  (err) => {
                    console.log(err);
                  }
                );
            }

            else {

              this.budgetProvider.signBudgetSinDoc(this.budget.id, {imagen: localStorage.getItem('signatureClient')})
                .subscribe(
                  (data: any[]) => {
                    for (let k = 0; k < this.docsGeneral.length; k++) {
                      let idsFinalDocs = [];
                      idsFinalDocs.push(this.docsGeneral[k].id);
                      this.generalProvider
                        .insertarFirmaDocumento(idsFinalDocs, this.budget.id, listFirma)
                        .subscribe((data) => {
                            if (!data) {
                              this.loadingController
                                .dismiss()
                                .then(() => errorToastMessage('', this.toastCtrl));
                            } else {
                              let arraybuf = data;
                              let blob = new Blob([data as BlobPart], {
                                type: 'application/pdf',
                              });

                              setTimeout(() => {
                                this.budgetProvider.sendDoc(this.budget.id, blob, this.docsGeneral[k].idSubtipo,
                                  this.docsGeneral[k].nombreFichero.replace('/', '_'))
                                  .subscribe(
                                    (data: any[]) => {
                                      if (k == this.docsGeneral.length - 1) {
                                        this.isSignatureSuccessfull = true;
                                        this.loadingController
                                          .dismiss()
                                          .then(() => setTimeout(() => {
                                            this.isSignatureSuccessfull = false;
                                            this.onDismiss();
                                            // está a 3 segundos para que se vea la imagen.
                                          }, 3000));
                                      }
                                    },
                                    (err) => {
                                      console.log(err);
                                    }
                                  );
                              }, 3500);
                            }
                          },
                          (err) => {
                            this.loadingController
                              .dismiss()
                              .then(() => errorToastMessage('', this.toastCtrl));
                            console.log('err', err);
                          }
                        );
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
            }
          }
        );
      });
  }

  onClickedSignaturePad() {
    this.signatureProvider.getSignatureEmpty() === this.signatureClient
      ? (this.isSignEmpty = true)
      : (this.isSignEmpty = false);
  }

  onDismiss() {
    this.modalController.dismiss({
      dismissed: true,
      update: true
    });
  }
}
