import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LoadingController, ModalController, NavParams, ToastController} from '@ionic/angular';
import {Client} from 'src/app/pages/client/client.model';
import {ClientProvider} from 'src/app/providers/client/client';
import {ClientMainContactDataModalComponent} from '../client-main-contact-data-modal/client-main-contact-data-modal.component';
import {toastMessage} from "../../providers/utils/utils";

@Component({
  selector: 'app-client-delegation-data-modal',
  templateUrl: './client-delegation-data-modal.component.html',
  styleUrls: ['./client-delegation-data-modal.component.scss'],
})
export class ClientDelegationDataModalComponent implements OnInit {
  @Input() client: Client
  @Input() isNewDelegation: boolean;
  @Input() isNewClient: boolean;
  @Input() tipoCliente: number;
  @Input() formTaxDataValue: {};
  @Input() localidades: string[]
  @Input() provincias: string[]
  @Input() pais
  @Input() loadedRoadTypes
  @Input() loadedCountries

  formDelegation: FormGroup;
  formTaxAndDelegationDataValue: {};

  roadTypes
  localizacion
  cpLoading: boolean

  positions: []

  cp: any;

  constructor(
    private modalController: ModalController,
    private clientProvider: ClientProvider,
    private loadingController: LoadingController,
    private toastController: ToastController,
  ) {
  }

  ngOnInit() {

    this.getCountries()
    this.getPositions()

    this.formDelegation = new FormGroup({
      via: new FormControl(
        this.client && this.client.delegacionPrincipal ? this.client.delegacionPrincipal.via : null, {
          updateOn: 'change',
          validators: [Validators.required],
        }
      ),
      direccion: new FormControl(
        this.client && this.client.delegacionPrincipal ? this.client.delegacionPrincipal.direccion : null,
        {
          updateOn: 'change',
          validators: [Validators.required],
        }
      ),
      numero: new FormControl(
        this.client && this.client.delegacionPrincipal ? this.client.delegacionPrincipal.numero : null,
        {
          updateOn: 'change',
          validators: [Validators.required],
        }
      ),
      bloque: new FormControl(
        '',
        {
          updateOn: 'change',
          validators: [],
        }
      ),
      piso: new FormControl(
        '',
        {
          updateOn: 'change',
          validators: [],
        }
      ),
      puerta: new FormControl(
        '',
        {
          updateOn: 'change',
          validators: [],
        }
      ),
      acceso: new FormControl(
        '',
        {
          updateOn: 'change',
          validators: [],
        }
      ),
      pais: new FormControl(
        this.client && this.client.delegacionPrincipal ? this.client.delegacionPrincipal?.codigoPostal?.paisDTO?.id : null,
        {
          updateOn: 'change',
          validators: [Validators.required],
        }
      ),
      cp: new FormControl(
        this.client && this.client.delegacionPrincipal ? this.client.delegacionPrincipal?.codigoPostal?.codigoPostal : null,
        {
          updateOn: 'change',
          validators: [Validators.required],
        }
      ),
      ciudad: new FormControl(
        this.client && this.client.delegacionPrincipal ? this.client.delegacionPrincipal?.codigoPostal?.listLocalizacion1?.[0] : null,
        {
          updateOn: 'change',
          validators: [Validators.required],
        }
      ),
      provincia: new FormControl(
        this.client && this.client.delegacionPrincipal ? this.client.delegacionPrincipal?.codigoPostal?.listLocalizacion2?.[0] : null,
        {
          updateOn: 'change',
          validators: [],
        }
      ),
      esPrincipal: new FormControl(
        true,
        {
          updateOn: 'change',
          validators: [Validators.required],
        }
      ),
      esEnvio: new FormControl(
        this.client && this.client.delegacionPrincipal ? this.client.delegacionPrincipal?.esEnvio : false,
        {
          updateOn: 'change',
          validators: [Validators.required],
        }
      ),
      esFiscal: new FormControl(
        true,
        {
          updateOn: 'change',
          validators: [Validators.required],
        }
      ),
      esInstalacion: new FormControl(
        this.client && this.client.delegacionPrincipal ? this.client.delegacionPrincipal?.esInstalacion : false,
        {
          updateOn: 'change',
          validators: [Validators.required],
        }
      ),
    });
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  //TODO añadir el formulario de tax-data a delegation para cliente nuevo

  onCreateNewClientDelegation() {
    this.formTaxAndDelegationDataValue = {...this.formTaxDataValue, ...this.formDelegation.value}
    this.onOpenClientMainContactFormDataModalComponent(
      this.isNewClient,
      this.tipoCliente,
      this.formTaxAndDelegationDataValue,
      this.localizacion,
      this.positions,
    );
    this.onDismiss();
  }

  onOpenClientMainContactFormDataModalComponent(
    isNewClient,
    tipoCliente,
    formTaxAndDelegationDataValue,
    localizacion,
    positions
  ) {
    this.modalController
      .create({
        component: ClientMainContactDataModalComponent,
        cssClass: 'client-contact-data-modal',
        componentProps: {
          isNewClient,
          tipoCliente,
          formTaxAndDelegationDataValue,
          localizacion,
          positions
        },
      })
      .then((modalEl) => {
        modalEl.present();
      });
  }

  getCountries() {
    this.clientProvider.getCountries().subscribe((data) => {
      if (data['codigo'] != 200) {
        this.loadingController
          .dismiss()
          .then(() => this.toastError(data['mensaje']));
      } else {
        this.loadedCountries = data['object'];
      }
    });
  }

  getPositions() {
    this.clientProvider.getPositions().subscribe((data) => {
      if (data['codigo'] != 200) {
        this.loadingController
          .dismiss()
          .then(() => this.toastError(data['mensaje']));
      } else {
        this.positions = data['object'];
      }
    });
  }

/*  getLocalizacion(cp, idPais) {
    this.cpLoading = true;
    if (cp.length >= 5) {
      console.log(cp, idPais)
      this.clientProvider.getLocalizacion(cp, idPais).subscribe(data => {
        console.log(data['object'])
        this.localizacion = data['object']
        if (data['codigo'] == '200') {
          this.localidades = this.localizacion.listLocalizacion1;
          this.provincias = this.localizacion.listLocalizacion2;
          this.cpLoading = false;
          console.log(data);
        } else {
          this.toastError(data['mensaje'])
          this.cpLoading = false;
        }
      }, err => {
        this.toastError(err.message)
        this.cpLoading = false;
      })
    }
  }  */

  toastError(msg) {
    this.toastController
      .create({
        message: msg,
        duration: 1500,
        position: 'bottom',
      })
      .then((toastEl) => {
        toastEl.present();
      });
  }

  getLocalizacion(cp, idPais) {
    if (cp.length >= 5) {
      this.cpLoading = true;
      this.formDelegation.controls['provincia'].setValue('');
      this.formDelegation.controls['ciudad'].setValue('');
      this.clientProvider.getLocalizacion(cp, idPais).subscribe(data => {
        if (data['codigo'] == '200') {
          this.localizacion = data['object']
          this.cp = data['object'];
          this.localidades = data['object'].listLocalizacion1;
          this.formDelegation.controls['ciudad'].setValue(data['object'].valorCampo1);
          this.formDelegation.controls['provincia'].setValue(data['object'].valorCampo2);
          this.cpLoading = false;
        } else {
          this.formDelegation.controls['ciudad'].setValue('');
          toastMessage(data['mensaje']).then(() => this.cpLoading = false);
        }
      }, err => {
        toastMessage().then(() => this.cpLoading = false);
      })
    } else {
      this.vaciaCampos();
    }
  }

  buscarProvincia(ciudad, idPais) {
    let cp = this.cp.codigoPostal;
    if (cp) {
      this.getProvincia(this.cp.codigoPostal, ciudad, idPais);
    }
  }

  getProvincia(cp, localidad, idPais) {
    this.formDelegation.controls['provincia'].setValue('');
    this.clientProvider.getProvincia(cp, idPais, localidad).subscribe(data => {
      this.cp = data['object'];
      if (data['codigo'] == '200') {
        this.localidades = data['object'].listLocalizacion1;
        this.formDelegation.controls['provincia'].setValue(data['object'].valorCampo2);
      } else {
        toastMessage(data['mensaje']);
      }
    }, err => {
      toastMessage()
    })
  }

  vaciaCampos() {
    this.localidades = [];
  }

}
