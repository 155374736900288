import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { delay, map, take, tap } from 'rxjs/operators';
import { Discount } from './discount.model';

@Injectable({
  providedIn: 'root'
})
export class DiscountsService {
  private _discounts = new BehaviorSubject<Discount[]>([])

  get discounts() {
    return this._discounts.asObservable()
  }

  constructor(
    private loadingController: LoadingController
  ) { }

  getDiscount(id: number) {
    return this.discounts.pipe(
      take(1),
      map((discounts) => {
        return {...discounts.find((d) => d.id === id )}
      })
    )
  }

  addDiscount(id: number, descripcion: string, valor: number,  tipo: boolean, editable: boolean, valorMaximo: boolean) {
    const newDiscount = new Discount(
      id,
      descripcion,
      valor,
      tipo,
      editable,
      valorMaximo
    );
    return this.discounts.pipe(
      take(1),
      delay(500),
      tap((discounts) => {
        this._discounts.next(discounts.concat(newDiscount))
        this.loadingController.dismiss()
      })
    )
  }

  deleteDiscount(discountId: number) {
    return this.discounts.pipe(
      take(1),
      tap(discounts => {
        this._discounts.next(discounts.filter(d => d.id !== discountId))
      })
    )
  }

  clearDiscounts() {
    return this.discounts.pipe(
      take(1),
      tap(discounts => {
        this._discounts.next(discounts = [])
      })
    )
  }

}
