import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  LoadingController,
  ModalController,
  NavController, Platform,
  ToastController,
} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {ClientMainContactDataModalComponent} from '../../../modals/client-main-contact-data-modal/client-main-contact-data-modal.component';
import {ClientProvider} from '../../../providers/client/client';
import {ContactsProvider} from '../../../providers/contacts/contacts';
import {Client} from '../client.model';
import {EmailComposer, EmailComposerOptions} from "@awesome-cordova-plugins/email-composer/ngx";
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';

@Component({
  selector: 'app-client-contacts',
  templateUrl: './client-contacts.page.html',
  styleUrls: ['./client-contacts.page.scss'],
})
export class ClientContactsPage implements OnInit {
  client: Client;
  clientSub: Subscription;
  positions
  contactoPrincipal
  loadedContacts = []

  constructor(
    private clientProvider: ClientProvider,
    private contactsProvider: ContactsProvider,
    private loadingController: LoadingController,
    private navController: NavController,
    private modalController: ModalController,
    private toastController: ToastController,
    private route: ActivatedRoute,
    private emailComposer: EmailComposer,
    private platform: Platform,
    private callNumber: CallNumber
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap) => {
      if (!paramMap.has('clienteId')) {
        this.navController.navigateBack('/search');
        return;
      } else {
        this.clientSub = this.clientProvider
          .getClient(+paramMap.get('clienteId'))
          .subscribe((client) => {
            this.client = client;
            this.contactoPrincipal = this.client.contactoPrincipal
          });
      }
    });
    this.getContacts()
    this.getPositions()

  }

  onClickBack() {
    this.navController.navigateBack(`client/${this.client.id}/client-detail`);
  }

  onOpenClientMainContactFormDataModalComponent(client, isNewContact, contactoPrincipal?) {
    this.modalController
      .create({
        component: ClientMainContactDataModalComponent,
        cssClass: 'client-contact-data-modal',
        componentProps: {
          client,
          isNewContact,
          contactoPrincipal,
          positions: this.positions
        },
      })
      .then((modalEl) => {
        modalEl.present();
      });
  }

  getContacts() {
    this.loadingController
      .create({
        message: 'Cargando contactos...',
        spinner: 'crescent',
      })
      .then((loadingEl) => {
        loadingEl.present();
        this.contactsProvider
          .getClientContacts(this.client.id)
          .subscribe((data) => {
            if (data['codigo'] != 200) {
              this.loadingController
                .dismiss()
                .then(() => this.toastError(data['mensaje']));
            } else {
              this.loadedContacts = data['object'];
            }
            this.loadingController.dismiss();
          });
      });
  }


  getPositions() {
    this.clientProvider.getPositions().subscribe(data => {
      if (data['codigo'] != 200) {
        this.loadingController
          .dismiss()
          .then(() => this.toastError(data['mensaje']));
      } else {
        this.positions = data['object']
      }
    })
  }

  onAddNewContact() {
    this.onOpenClientMainContactFormDataModalComponent(this.client, true);
  }

  toastError(msg) {
    this.toastController
      .create({
        message: msg,
        duration: 1500,
        position: 'bottom',
      })
      .then((toastEl) => {
        toastEl.present();
      });
  }


  llamar(telefono) {
    this.callNumber.callNumber(telefono, true)
      .then(res => console.log('Launched dialer!', res))
      .catch(err => console.log('Error launching dialer', err));
  }

  async mail(clientEmail) {
   // let userMail = localStorage.getItem('userMail');
    if (!this.platform.is('ios') && !this.platform.is('android')) {
      window.location.href = "mailto:" +clientEmail+ "?subject=Contacto&body=";
    } else {
      const email: EmailComposerOptions = {
        to: clientEmail,
        attachments: [``],
        subject: 'Contacto',
        body: ''
      };
      await this.emailComposer.open(email).catch(err => console.error('Error launching dialer', err))
    }
  }

}
