import { Component, Input, OnInit } from '@angular/core';
import {
  LoadingController,
  ModalController,
  NavParams,
  ToastController,
} from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Client } from 'src/app/pages/client/client.model';
import { ClientDelegationDataModalComponent } from '../client-delegation-data-modal/client-delegation-data-modal.component';
import { ClientProvider } from 'src/app/providers/client/client';

// Factory function
const getEmptyClient = (tipoCliente: number = 0): Client => {
  return {
    estadoCliente: null,
    razonSocial: null,
    fechaCreacion: null,
    segmentacion: {},
    id: null,
    tipoCliente,
    tiposDocumentos: [],
    usuariosAsignados: null,
    nombre: '',
  };
};

// Upsert Modal
@Component({
  selector: 'app-client-tax-data-modal',
  templateUrl: './client-tax-data-modal.component.html',
  styleUrls: ['./client-tax-data-modal.component.scss'],
})
export class ClientTaxDataModalComponent implements OnInit {
  @Input() client: Client = getEmptyClient();
  @Input() segment;
  @Input() tipoCliente: number;
  @Input() numDocumento: string;
  @Input() activities: [];


  formPrivateClient: FormGroup;
  formBusinessClient: FormGroup;
  isNewClient: boolean;
  loadedCountries
  loadedRoadTypes = [];
  loadedDelegations = [];
  localidades: string[];
  provincias: string[];
  pais;
  localizacion;

  surname1Required = false;
  surname2Required = false;
  labelRequired = false;
  activityRequired = false;

  labelCompanyRequired = false;
  nameCompanyRequired = false;
  activityCompanyRequired = false;

  constructor(
    private modalController: ModalController,
    private loadingController: LoadingController,
    private toastCtrl: ToastController,
    private clientProvider: ClientProvider,
  ) {}

  ngOnInit() {
    this.getRoadtipes()

    this.isNewClient = !this.client.id;
    this.tipoCliente = this.client.id
      ? this.client.tipoCliente
      : this.tipoCliente;


    // Cualificación

    this.surname1Required = this.clientProvider.qualificationParticular.find(x => x.id == 16);
    this.surname2Required = this.clientProvider.qualificationParticular.find(x => x.id == 17);
    this.labelRequired = this.clientProvider.qualificationParticular.find(x => x.id == 13);
    this.activityRequired = this.clientProvider.qualificationParticular.find(x => x.id == 31);

    this.labelCompanyRequired = this.clientProvider.qualificationCompany.find(x => x.id == 3);
    this.nameCompanyRequired = this.clientProvider.qualificationCompany.find(x => x.id == 6);
    this.activityCompanyRequired = this.clientProvider.qualificationCompany.find(x => x.id == 8);

    this.formPrivateClient = new FormGroup({
      nombre: new FormControl(this.client.nombre ? this.client.nombre : null, {
        updateOn: 'change',
        validators: [Validators.required],
      }),
      apellido1: new FormControl(this.client.apellido1 || null, {
        updateOn: 'change',
        validators: [this.surname1Required ? Validators.required : Validators.nullValidator],
      }),
      apellido2: new FormControl(this.client.apellido2 || null, {
        updateOn: 'change',
        validators: [this.surname2Required ? Validators.required : Validators.nullValidator],
      }),
      tipoDocumento: new FormControl(
        this.clientProvider.idTipoDoc,
        {
          updateOn: 'change',
          validators: [Validators.required],
        }
      ),
      numDocumentacion: new FormControl(
        this.numDocumento,
        {
          updateOn: 'change',
          validators: [Validators.required],
        }
      ),
      sexo: new FormControl(this.client.sexo || null, {
        updateOn: 'change',
        validators: [Validators.required],
      }),

      actividad: new FormControl( null, {
        updateOn: 'change',
        validators: [this.activityRequired ? Validators.required : Validators.nullValidator],
      }),

      tipoCliente: new FormControl(this.client.tipoCliente),

      fuente: new FormControl(this.client.segmentacion?.fuente?.id || null, {
        updateOn: 'change',
        validators: [Validators.required],
      }),
      segmento: new FormControl(
        this.client.segmentacion?.segmento?.id || null,
        {
          updateOn: 'change',
          validators: [Validators.required],
        }
      ),
      etiqueta: new FormControl('', {
        updateOn: 'change',
        validators: [this.labelRequired ? Validators.required : Validators.nullValidator],
      }),
    });



    this.formBusinessClient = new FormGroup({
      razonSocial: new FormControl(this.client.razonSocial || null, {
        updateOn: 'blur',
        validators: [Validators.required],
      }),
      nombreComercial: new FormControl(this.client.nombreComercial || '', {
        updateOn: 'blur',
        validators: [this.nameCompanyRequired ? Validators.required : Validators.nullValidator],
      }),
      tipoDocumento: new FormControl(
        this.clientProvider.idTipoDoc,
        {
          updateOn: 'change',
          validators: [Validators.required],
        }
      ),
      numDocumentacion: new FormControl(
        this.numDocumento,
        {
          updateOn: 'blur',
          validators: [Validators.required],
        }
      ),
      tipoCliente: new FormControl(this.clientProvider.idTipoDoc == 0? 1 : 2, {
        updateOn: 'change',
        validators: [Validators.required],
      }),
      actividad: new FormControl( null, {
        updateOn: 'change',
        validators: [this.activityCompanyRequired ? Validators.required : Validators.nullValidator],
      }),
      fuente: new FormControl(this.client.segmentacion?.fuente?.id || null, {
        updateOn: 'change',
        validators: [Validators.required],
      }),
      segmento: new FormControl(
        this.client.segmentacion?.segmento?.id || null,
        {
          updateOn: 'change',
          validators: [Validators.required],
        }
      ),
      etiqueta: new FormControl('', {
        updateOn: 'change',
        validators: [this.labelCompanyRequired ? Validators.required : Validators.nullValidator],
      }),
    });
  }

  onCreateNewClient() {
    if (this.isNewClient) {
      this.onOpenClientDelegationFormDataModal(
        this.isNewClient,
        this.tipoCliente,
        this.tipoCliente == 0
          ? this.formPrivateClient.value
          : this.formBusinessClient.value
      );
    }
    this.onDismiss();
  }

  getRoadtipes() {
    this.clientProvider.getRoadTypes().subscribe((data) => {
      if (data['codigo'] != 200) {
        this.loadingController
          .dismiss()
          .then(() => this.toastError(data['mensaje']));
      } else {
        Object.keys(data['object']).forEach((key) => {
          this.loadedRoadTypes.push({
            id: key,
            descripcion: data['object'][key],
          });
        });
      }
    });
  }

  getDelegations() {
    this.loadingController
      .create({
        message: 'Cargando delegaciones...',
        spinner: 'crescent',
      })
      .then((loadingEl) => {
        loadingEl.present();
        this.clientProvider
          .getDelegations(this.client.id)
          .subscribe((data) => {
            if (data['codigo'] != 200) {
              this.loadingController
                .dismiss()
                .then(() => this.toastError(data['mensaje']));
            } else {
              this.loadedDelegations = data['object'];
            }
            this.loadingController.dismiss();
          });
      });
  }

  onOpenClientDelegationFormDataModal(isNewClient, tipoCliente, formTaxDataValue) {
    this.modalController
      .create({
        component: ClientDelegationDataModalComponent,
        cssClass: 'client-delegation-data-modal',
        componentProps: {
          loadedCuntries: this.loadedCountries,
          loadedRoadTypes: this.loadedRoadTypes,
          isNewClient,
          tipoCliente,
          formTaxDataValue,
        },
      })
      .then((modalEl) => {
        modalEl.present();
      });
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  toastError(msg) {
    this.toastCtrl
      .create({
        message: msg,
        duration: 1500,
        position: 'bottom',
      })
      .then((toastEl) => {
        toastEl.present();
      });
  }
}
