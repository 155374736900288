import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LoadingController, ModalController} from '@ionic/angular';
import {BankAccount} from 'src/app/providers/bank-account.model';
import {BankAccountsService} from 'src/app/providers/bank-account.service';
import {checkResponseCode, IBANSeparateCharacters, toastMessage} from 'src/app/providers/utils/utils';
import {NewBudget} from "../../providers/budget/newBudget.model";
import {BudgetProvider} from "../../providers/budget/budget";
import {UtilProvider} from "../../providers/utils/util";

@Component({
  selector: 'app-client-new-budget-method-payment-modal',
  templateUrl: './client-new-budget-method-payment-modal.component.html',
  styleUrls: ['./client-new-budget-method-payment-modal.component.scss'],
})
export class ClientNewBudgetMethodPaymentModalComponent implements OnInit {

  public IBANSeparateCharacters = IBANSeparateCharacters;

  formMethodPayment: FormGroup;
  loadedBankAccounts: BankAccount[];
  paymentMethods = [];

  constructor(
    private modalController: ModalController,
    private bankAccountsService: BankAccountsService,
    private loadingController: LoadingController,
    private budgetProvider: BudgetProvider,
    private utils: UtilProvider
  ) {
  }

  ngOnInit() {

    this.loadingController
      .create({
        message: '',
        keyboardClose: true,
      })
      .then((loadingEl) => {
        loadingEl.present();

        let empDato = JSON.parse(localStorage.getItem('empDato'));

        this.budgetProvider.getPaymentMethods(empDato.id, this.budgetProvider.client.id).subscribe(data => {
          if (!checkResponseCode(data['codigo'])) {
            loadingEl.dismiss().then(() => toastMessage(data['mensaje']));
          } else {
            this.paymentMethods = data['object'];

            this.paymentMethods.forEach(x => {
              if (this.budgetProvider.paymentMethod) {
                if (x.id == this.budgetProvider.paymentMethod.id) {
                  this.budgetProvider.paymentMethod = x;
                }
              }
            });

            loadingEl.dismiss();
          }
        }, err =>
          loadingEl.dismiss().then(() => toastMessage(err.message)));
      });

   /* this.formMethodPayment = new FormGroup({
      formaPago: new FormControl(
        null
      ),
      cuenta: new FormControl(
        null
      ),

    })  */
  }

  onClickSaveMethodPayment() {
    if (this.budgetProvider.paymentMethod.requiereCuenta) {
      if (!this.budgetProvider.bankAccount.numeroCuenta) {
        if (!this.validateIBAN(this.budgetProvider.bankAccount)) {
          this.utils.alert('Atención', '', 'Debe introducir un número de cuenta válido');
          return;
        }
      }
    }
    this.onDismiss();
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  compareById(o1, o2) {
    return o1.id === o2.id;
  }

  selectPrincipalAccount() {
    this.budgetProvider.bankAccount = null;
    this.budgetProvider.paymentMethod.informacionBancariaList.forEach(x => {
      if (x.principal) {
        this.budgetProvider.bankAccount = x;
      }
    })
  }

  validateIBAN(IBAN) {

    //Se pasa a Mayusculas
    IBAN = IBAN.toUpperCase();
    //Se quita los blancos de principio y final.
    IBAN = IBAN.trim();
    IBAN = IBAN.replace(/\s/g, ""); //Y se quita los espacios en blanco dentro de la cadena

    var letra1,letra2,num1,num2;
    var isbanaux;
    var numeroSustitucion;
    //La longitud debe ser siempre de 24 caracteres
    if (IBAN.length != 24) {
      return false;
    }

    // Se coge las primeras dos letras y se pasan a números
    letra1 = IBAN.substring(0, 1);
    letra2 = IBAN.substring(1, 2);
    num1 = this.getnumIBAN(letra1);
    num2 = this.getnumIBAN(letra2);
    //Se sustituye las letras por números.
    isbanaux = String(num1) + String(num2) + IBAN.substring(2);
    // Se mueve los 6 primeros caracteres al final de la cadena.
    isbanaux = isbanaux.substring(6) + isbanaux.substring(0,6);

    //Se calcula el resto, llamando a la función modulo97, definida más abajo
    let resto = this.modulo97(isbanaux);
    if (Number(resto) == 1){
      return true;
    }else{
      return false;
    }
  }

  modulo97(iban) {
    var parts = Math.ceil(iban.length/7);
    var remainer = "";

    for (var i = 1; i <= parts; i++) {
      remainer = String(parseFloat(remainer+iban.substr((i-1)*7, 7))%97);
    }

    return remainer;
  }

  getnumIBAN(letra) {
    let ls_letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return ls_letras.search(letra) + 10;
  }

}
