export class parseClient {

  parsed: any;

  particular(object, localizacion, sexo) {
    let fechaNacimiento = object.fechaNacimiento ? object.fechaNacimiento.split('/') : null;
    if (fechaNacimiento != null) {
      fechaNacimiento[0] = fechaNacimiento[0].length == 1 ? '0' + fechaNacimiento[0] : fechaNacimiento[0];
      fechaNacimiento[1] = fechaNacimiento[1].length == 1 ? '0' + fechaNacimiento[1] : fechaNacimiento[1];
      fechaNacimiento = fechaNacimiento[0] + '/' + fechaNacimiento[1] + '/' + fechaNacimiento[2] + ' 00:00:00';
    }

    // let fechaNacimiento = object.fechaNacimiento? object.fechaNacimiento.split('-') : null;
    // if (fechaNacimiento != null) {
    //   fechaNacimiento = fechaNacimiento[2] + '/' + fechaNacimiento[1] + '/' + fechaNacimiento[0] + ' 00:00:00';
    // }


    this.parsed = {
      "segmentacion": {},
      "apellido1": object.apellido1,
      "apellido2": object.apellido2,
      "sexo": sexo,
      "contactoPrincipal": {
        "nombre": object.nombrePrincipal,
        "sexo": object.sexoPrincipal,
        "telefono": '',
        "movil": object.movil,
        "cargo": {"id": object.cargo},
        "esPrincipal": object.esPrincipal,
        "esFiscal": object.esFiscal,
        "esEnvio": object.esEnvio,
        "fechaNacimiento": fechaNacimiento
      },
      "delegacionPrincipal": {
        "nombre": 'Sede central',
        "via": object.via,
        "direccion": object.direccion,
        "numero": object.numero,
        "codigoPostal": {
          "id": localizacion.id,
          "codigoPostal": localizacion.codigoPostal,
          "valorCampo1": localizacion.valorCampo1,
          "valorCampo2": localizacion.valorCampo2,
          "valorCampo3": localizacion.valorCampo3,
          "valorCampo4": localizacion.valorCampo4
        },
        "piso": object.piso,
        "puerta": object.puerta,
        "bloque": object.bloque,
        "acceso": object.acceso,
        "esPrincipal": object.esPrincipal,
        "esFiscal": object.esFiscal,
        "esEnvio": object.esEnvio,
        "esInstalacion": false
      }
    };

    if (object.nombre != '') {
      this.parsed.nombre = object.nombre;
    }

    // if (object.numDocumentacion != '') {
    //   this.parsed.tipoDocumento = 5;
    //   this.parsed.numDocumentacion = object.numDocumentacion.replace('-', '').length == 8 ? (0 + object.numDocumentacion.replace('-', '')) : object.numDocumentacion.replace('-', '');
    // }

    if (object.numDocumentacion != '') {
      this.parsed.tipoDocumento = object.tipoDocumento;
      this.parsed.numDocumentacion = object.numDocumentacion.replace('-', '').length == 8 ? (0 + object.numDocumentacion.replace('-', '')) : object.numDocumentacion.replace('-', '');
      //this.parsed.numDocumentacion = object.numDocumentacion;
    }

    if (object.email != '') {
      this.parsed.contactoPrincipal.email = object.email;
    }

    if (object.actividad != '') {
      this.parsed.actividadProfesional = {id: object.actividad.id};
    }

    if (object.fuente != '') {
      this.parsed.segmentacion.fuente = {"id": object.fuente};
    }

    if (object.segmento != '') {
      this.parsed.segmentacion.segmento = {"id": object.segmento};
    }

    if (object.etiqueta) {
      this.parsed.segmentacion.etiquetas = [{"id": object.etiqueta}];
    }

    if (object.apellido1Principal != '') {
      this.parsed.contactoPrincipal.apellido1 = object.apellido1Principal;
    }

    if (object.apellido2Principal != '') {
      this.parsed.contactoPrincipal.apellido2 = object.apellido2Principal;
    }


    return this.parsed;
  }

  empresa(object, localizacion, sexo) {
    this.parsed = {
      "segmentacion": {},
      "tipoCliente": object.tipoCliente,
      "contactoPrincipal": {
        "nombre": object.nombrePrincipal,
        "sexo": sexo,
        "telefono": '',
        "movil": object.movil,
        "cargo": {"id": object.cargo},
        "esPrincipal": object.esPrincipal,
        "esFiscal": object.esFiscal,
        "esEnvio": object.esEnvio
      },
      "delegacionPrincipal": {
        "nombre": 'Sede central',
        "via": object.via,
        "direccion": object.direccion,
        "numero": object.numero,
        "codigoPostal": {
          "id": localizacion.id,
          "codigoPostal": localizacion.codigoPostal,
          "valorCampo1": localizacion.valorCampo1,
          "valorCampo2": localizacion.valorCampo2,
          "valorCampo3": localizacion.valorCampo3,
          "valorCampo4": localizacion.valorCampo4
        },
        "esPrincipal": object.esPrincipal,
        "esFiscal": object.esFiscal,
        "esEnvio": object.esEnvio,
        "piso": object.piso,
        "puerta": object.puerta,
        "bloque": object.bloque,
        "acceso": object.acceso,
        "esInstalacion": false
      }
    };

    if (object.numDocumentacion != '') {
      this.parsed.tipoDocumento = object.tipoDocumento;
      this.parsed.numDocumentacion = object.numDocumentacion.replace('-', '').length == 8 ? (0 + object.numDocumentacion.replace('-', '')) : object.numDocumentacion.replace('-', '');
      // this.parsed.numDocumentacion = object.numDocumentacion;
    }

    if (object.razonSocial != '') {
      this.parsed.razonSocial = object.razonSocial;
    }

    // if (object.nombreComercial != '') {
    //   this.parsed.nombreComercial = object.nombreComercial;
    // }

    if (object.actividad != '') {
      this.parsed.actividad = {id: object.actividad.id};
    }

    if (object.email != '') {
      this.parsed.contactoPrincipal.email = object.email;
    }

    if (object.apellido1Principal != '') {
      this.parsed.contactoPrincipal.apellido1 = object.apellido1Principal;
    }

    if (object.apellido2Principal != '') {
      this.parsed.contactoPrincipal.apellido2 = object.apellido2Principal;
    }


    if (object.fuente != '') {
      this.parsed.segmentacion.fuente = {"id": object.fuente};
    }

    if (object.segmento != '') {
      this.parsed.segmentacion.segmento = {"id": object.segmento};
    }

    if (object.etiqueta) {
      this.parsed.segmentacion.etiquetas = [{"id": object.etiqueta}];
    }

    return this.parsed;
  }

}
