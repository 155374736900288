import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiProvider} from '../api/api';

@Injectable({
  providedIn: 'root'
})
export class GeneralProvider {
  idSubtipo = 1;
  constructor(public http: HttpClient, private apiProvider: ApiProvider) {
  }


  insertarFirmaDocumento(idDocumentacionGeneral, idPresupuesto, listFirma) {
    const body = {
      listFirma: listFirma,
      lstIdsDocumentacion: idDocumentacionGeneral,
    };

    return this.apiProvider.post('generalWS/insertarFirmaDocumento', body, {
      params: {
        id: idPresupuesto,
        tipofuncionalidad: 2,
      },
      responseType: 'arraybuffer',
    });
  }

  obtenerDocumentacion(idPresupuesto) {
    return this.apiProvider.get('generalWS/obtenerDocumentacion', {
      params: {
        tipofuncionalidad: 3,
        id: idPresupuesto
      }
    });
  }

  getDocumentacion(idPresupuesto) {
    return this.apiProvider.post('generalWS/documentacion', {}, {
      params: {
        soloConFirma: false,
        idPresupuesto,
      }
    });
  }

  obtenerCamposFirma(idDocumentacionGeneral) {
    return this.apiProvider.post('generalWS/obtenerCamposFirma', idDocumentacionGeneral);
  }

  subirDocumento(file, idPresupuesto, subtipo, fileName) {
    let formData = new FormData();
    formData.append('file', file, fileName);
    return this.apiProvider.post('ofertaWS/subirDocumento', formData, {
      params: {
        idSubtipo: subtipo,
        idPresupuesto: idPresupuesto,
        pdfEditable: true
      }
    });
  }

  obtenerTipoDocumentacion() {
    return this.apiProvider.get("generalWS/obtenerTipoDocumentacion");
  }

  obtenerSubTipoDocumentacion(idDocumentacion) {
    return this.apiProvider.get(
      "generalWS/obtenerSubTipoDocumentacion/" + idDocumentacion
    );
  }

  eliminarDocumentacion(id, idUsuario, idClienteAplicacion, tipoFuncionalidad, idObject) {

    return this.apiProvider.get("generalWS/eliminarDocumentacion", {
      params: {
        id: id,
        idusuario: idUsuario,
        idclienteaplicacion: idClienteAplicacion,
        tipofuncionalidad: tipoFuncionalidad,
        idobject: idObject,
      }
    });
  }

  descargarDocumentacion(idDrive, idUsuario, idDocGeneral) {
    if (idDrive != undefined) {
      return this.apiProvider.get("generalWS/descargarDocumentacion", {
        params: {idDrive: idDrive, idusuario: idUsuario},
      });
    } else {
      return this.apiProvider.get("generalWS/descargarDocumentacion", {
        params: {idDocGeneral: idDocGeneral, idusuario: idUsuario},
      });
    }
  }

}
