import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { delay, take, tap } from 'rxjs/operators';
import { InternalAnnotation } from './internal-annotation.model';


@Injectable({
  providedIn: 'root',
})
export class InternalAnnotationsService {
  private _internalAnnotations = new BehaviorSubject<InternalAnnotation[]>([]);

  private _observaciones = new BehaviorSubject<string>('')

  get internalAnnotations() {
    return this._internalAnnotations.asObservable();
  }

  get observaciones() {
    return this._observaciones.asObservable();
  }

  constructor(
    private loadingController: LoadingController
  ) {}

  addInternalAnnotation(content: string) {
    const newInternalAnnotation = new InternalAnnotation(
      content
    );
    return this.internalAnnotations.pipe(
      take(1),
      tap((internalAnnotations) => {
        this._internalAnnotations.next(internalAnnotations.concat(newInternalAnnotation));
        this.loadingController.dismiss();
      }

      )
    )
  }

  addObservaciones(observacion: string) {
    return this.observaciones.pipe(
      take(1),
      tap((observaciones) => {
        this._observaciones.next(observaciones = observacion)
      })
    )
  }

}
