import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(private authService: AuthService, private router: Router) {}
 // Uso canLoad en lugar de canActivate, corre antes de que se coja el lazyload
  canLoad(
    route: Route,
    segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
      return this.authService.userIsAuthenticated.pipe(
        take(1),
        switchMap(isAuthenticated => {
          if (!isAuthenticated) {
            return this.authService.autoLogin();
          } else {
            return of(isAuthenticated);
          }
        }),
        tap( isAuthenticated => {
          if (!isAuthenticated) {
            this.router.navigateByUrl('/auth');
          }
        })
      );
    }

  // canActivate(
  //   route: Route,
  //   segments: UrlSegment[]
  //   ): Observable<boolean> | Promise<boolean> | boolean {
  //   if (!this.authService.userIsAuthenticated) {
  //     this.router.navigateByUrl('/auth');
  //   }
  //   return this.authService.userIsAuthenticated;
  // }


}
